import { categories } from '@y2/categories';
import * as api from '../../../services/api';
import { fromApiItem } from './from-api-item';
import { getTypeFromHomeCategory } from './type-from-home-category';
import type { RecommendationsHomeCategory } from '@y2/recommendations';

const VEHICLES_BOOST_FACTOR = 2;

/**
 * fetches recommendations according to spec
 * @see spec https://docs.google.com/document/d/1CsnFXjpAp4sNGSoqCkbLykVXRcMBAifgPQAPCEozv5w
 * @returns items mapped for easier usage
 * @throws {Error} when `homeCategory` is invalid
 */
export async function getRecommendations(
  userId: string,
  homeCategory: RecommendationsHomeCategory,
) {
  const type = getTypeFromHomeCategory(homeCategory);
  if (type === null) {
    throw new Error('invalid home-category');
  }

  const { categoryId, subCategoryId } = homeCategory;
  const isMerchantCategory = categoryId === categories.vehicles.id;
  const res = await api.recommendations.get({
    categoryId,
    subCategoriesIds:
      categoryId === categories.realEstate.id && subCategoryId
        ? [subCategoryId]
        : undefined,
    type: 'home',
    count: 20,
    userId,
    boostMerchant: isMerchantCategory ? true : undefined,
    boostMerchantValue: isMerchantCategory ? VEHICLES_BOOST_FACTOR : undefined,
    typeRequest: 'default',
  });
  const [rawItems] = res.data.data;

  return { data: rawItems.map(fromApiItem(type)), isMerchantCategory };
}
