import { createContext, ReactNode, useContext } from 'react';
import { useInspect } from './useInspect';

export function createStoreContext<T>() {
  const StoreContext = createContext<T | null>(null);

  function useStore() {
    const store = useContext<T | null>(StoreContext);
    if (store === null) {
      throw new Error('useStore must be used within a StoreProvider.');
    }

    return store;
  }

  type Props<T> = {
    children: ReactNode;
    value: T;
  };

  function StoreProvider({ children, value }: Props<T>) {
    useInspect(value);
    // prettier-ignore
    return (
      <StoreContext.Provider value={value}>
        {children}
      </StoreContext.Provider>
    )
  }

  return {
    useStore,
    StoreProvider,
  };
}
