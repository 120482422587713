import React, { ReactNode, useEffect } from 'react';
import Script from 'next/script';
import { DFP_API } from '../consts';
import { beforeInitHooks } from '../before-init-hooks';
import { DfpContextProvider } from '../dfp-context/dfp-context-provider';

declare global {
  interface Window {
    googletag: any;
  }
}

const setHooksBeforeInit = (disableInitialLoad: boolean) => {
  window.googletag = window.googletag || { cmd: [] };
  const { googletag } = window;

  googletag.cmd.push(() => {
    const pubadsFunc = googletag.pubads();
    pubadsFunc.collapseEmptyDivs();
    if (disableInitialLoad) {
      pubadsFunc.disableInitialLoad();
    }
    pubadsFunc.enableSingleRequest();
    googletag.pubads().setForceSafeFrame(true);
    googletag.enableServices();
    beforeInitHooks.executeHooks();
  });
};

type Props = {
  children?: ReactNode;
  isDevMode?: boolean;
};

export const DfpProvider: React.FC<Props> = ({
  children,
  isDevMode = false,
}) => {
  useEffect((disableInitialLoad = false) => {
    setHooksBeforeInit(disableInitialLoad);
  }, []);

  return (
    <>
      {!isDevMode && (
        <Script
          id="dfp-provider"
          async
          src={DFP_API}
          strategy="afterInteractive"
        />
      )}
      <DfpContextProvider isDevMode={isDevMode}>{children}</DfpContextProvider>
    </>
  );
};
