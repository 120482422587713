import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import styles from './nanorep-chat.module.scss';
import { NanorepForNoScript } from './nanorep-for-noscript';
import {
  DEFAULT_ACCOUNT_ID,
  DEFAULT_CHAT_ID,
  DEFAULT_WEBSITE_ID,
  WIDGET_ID,
} from './constants';
import {
  setMachineConfig,
  setWidgetConfig,
  setWidgetIdOnLoad,
  isMobileDevice,
} from './helpers';

type Props = {
  customAccountId?: string;
  customWebSiteId?: string;
  customChatId?: string;
  customWidgetId?: string;
  forceShow?: boolean;
};

const addY2NanoRepClass = () => {
  const className = styles['y2-nanorep'];
  const bodyElm = document.querySelector('body');
  bodyElm?.classList.add(className);
};

const getY2WidgetId = () => {
  if (typeof window === 'undefined') return '';
  return isMobileDevice() ? WIDGET_ID.mobile : WIDGET_ID.desktop;
};

export const NanorepChat: React.FC<Props> = ({
  customAccountId,
  customWebSiteId,
  customChatId,
  customWidgetId,
  forceShow = false,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const accountId = customAccountId || DEFAULT_ACCOUNT_ID;
  const websiteId = customWebSiteId || DEFAULT_WEBSITE_ID;
  const chatId = customChatId || DEFAULT_CHAT_ID;
  const widgetId = customWidgetId || getY2WidgetId();

  useEffect(() => {
    addY2NanoRepClass();
    setMachineConfig(accountId, websiteId, chatId);
    setWidgetConfig();
    setShow(forceShow ? true : !isMobileDevice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return show ? (
    <>
      <NanorepForNoScript accountId={accountId} websiteId={websiteId} />
      <Script
        id="nanorep-chat"
        async
        strategy="lazyOnload"
        src="https://yad2.nanorep.co/web/floating-widget.js?account=yad2"
        onLoad={() => setWidgetIdOnLoad(widgetId)}
      />
    </>
  ) : null;
};

export default NanorepChat;
