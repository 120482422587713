import type { RecommendationsHomeCategory } from '@y2/recommendations';
import type { RecommendationItem } from './from-api-item';
import { categories } from '@y2/categories';

export function getTypeFromHomeCategory(
  homeCategory: RecommendationsHomeCategory,
): RecommendationItem['type'] | null {
  if (homeCategory.categoryId === categories.vehicles.id) {
    return 'vehicle';
  } else if (homeCategory.categoryId === categories.products.id) {
    return 'product';
  } else if (homeCategory.categoryId === categories.realEstate.id) {
    const { subCategories } = categories.realEstate;
    // eslint-disable-next-line custom-rules/no-nested-if-conditions
    if (homeCategory.subCategoryId === subCategories.forSale.id) {
      return 'realEstate/sale';
    } else if (homeCategory.subCategoryId === subCategories.rent.id) {
      return 'realEstate/rent';
    } else if (homeCategory.subCategoryId === subCategories.commercial.id) {
      return 'realEstate/commercial';
    }
  }
  return null;
}
