import NextImage from 'next/image';
import { useEffect, useState } from 'react';
import texts from './incoming-snackbar.texts.json';
import styles from './incoming-snackbar.module.scss';
import { CloseIcon } from '@y2/mango/components/icons';
import { formatChannelTitle } from './utils/incoming-snackbar.utils';
import { ChatMessageNotification } from '@y2/socket-clients/sendbird-updates/types';

const showTimeout = 10000;

type Props = {
  message?: ChatMessageNotification;
};

export const IncomingSnackbar = ({ message }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!message) return;

    setIsVisible(true);
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, showTimeout);

    return () => clearTimeout(timeout);
  }, [message]);
  if (!isVisible || !message) return null;

  const {
    channelName,
    channelId,
    senderName,
    message: messageContent,
    channelCoverImage,
  } = message;

  const channelNameTitle = formatChannelTitle(channelName);

  return (
    <div className={styles.snackbar} data-testid="incoming-snackbar">
      <a
        href={`/chat/v2/conversation/${channelId}`}
        className={styles.linkContent}
      >
        <NextImage
          src={channelCoverImage}
          className={styles.image}
          height={64}
          width={64}
          alt={texts.imageAlt}
        />
        <p className={styles.text}>
          <span className={styles.title}>
            {texts.incomingMessage}
            {senderName}
          </span>
          <span className={styles.message}>{messageContent}</span>
          <span className={styles.details}>{channelNameTitle}</span>
        </p>
      </a>
      <button
        aria-label={texts.close}
        className={styles.close}
        onClick={() => setIsVisible(false)}
      >
        <CloseIcon />
      </button>
    </div>
  );
};
