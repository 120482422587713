import { chatClient } from '@y2/api-clients/chat';
import { authClient } from '@y2/api-clients/auth';
import { latestSearchesClient } from '@y2/api-clients/latest-searches';
import { recommendationsClient } from '@y2/api-clients/recommendations';
import { myAlertsClient } from '@y2/api-clients/my-alerts';
import { addressMasterClient } from '@y2/api-clients/address-master';
import { carouselsClient } from '@y2/api-clients/carousels';

export const auth = authClient();
export const latestSearches = latestSearchesClient();
export const recommendations = recommendationsClient();
export const alerts = myAlertsClient();
export const chat = chatClient();
export const addressMaster = addressMasterClient();
export const carousels = carouselsClient();
