import {
  LatestSearchesModel,
  Snapshot as LatestSearchesSnapshot,
} from '../../features/latest-searches/latest-searches.model';
import {
  RecommendationsModel,
  Snapshot as RecommendationsSnapshot,
} from '../../features/recommendations/recommendations.model';
import {
  AuthModel,
  Snapshot as AuthSnapshot,
} from '../../features/auth/auth.model';
import {
  AlertsModel,
  Snapshot as AlertsSnapshot,
} from '../../features/alerts/alerts.model';
import {
  FavoritesModel,
  Snapshot as FavoritesSnapshot,
} from '@y2/favorites/models';
import {
  NearMeAdsModel,
  Snapshot as NearMeAdsSnapShop,
} from '../../features/near-me-ads/near-me-ads.model';
import {
  LocationModel,
  Snapshot as LocationSnapShop,
} from '../../features/location/location.model';

import { environment } from '../../environments/environment';

export type Snapshot = {
  auth: AuthSnapshot;
  latestSearches: LatestSearchesSnapshot;
  recommendations: RecommendationsSnapshot;
  alerts: AlertsSnapshot;
  favorites: FavoritesSnapshot;
  nearMeAds: NearMeAdsSnapShop;
  location: LocationSnapShop;
};

export function createStore(initialState: Snapshot) {
  return {
    auth: new AuthModel(initialState.auth),
    latestSearches: new LatestSearchesModel(initialState.latestSearches),
    recommendations: new RecommendationsModel(initialState.recommendations),
    alerts: new AlertsModel(initialState.alerts),
    favorites: new FavoritesModel(
      {
        gwApiBasePath: environment.routes.api.gw,
        oldApiBasePath: environment.routes.api.old,
      },
      initialState.favorites,
    ),
    nearMeAds: new NearMeAdsModel(initialState.nearMeAds),
    location: new LocationModel(initialState.location),
  };
}
