import React, { useState, createContext, useEffect, ReactNode } from 'react';
import { appendEventListneresOnInit } from '../googletag-api';
import { DfpState } from './dfp-state';

export const DfpContext = createContext<DfpState | null>(null);

type Props = {
  children?: ReactNode;
  isDevMode: boolean;
};

export const DfpContextProvider: React.FC<Props> = ({
  children,
  isDevMode = false,
}) => {
  const [dfpContext] = useState<DfpState>(new DfpState(isDevMode));
  useEffect(() => {
    appendEventListneresOnInit(dfpContext);
  }, [isDevMode, dfpContext]);

  return (
    <DfpContext.Provider value={dfpContext}>{children}</DfpContext.Provider>
  );
};
