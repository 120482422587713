import { observer } from 'mobx-react-lite';
import { join } from 'path';
import styles from './note-row.module.scss';
import text from './note-row.text.json';

type Props = {
  isLoggedIn: boolean;
  likedItemsCount: number;
};

const getNotLoggedInText = (likedItemsCount: number): string => {
  let openLine = '';
  if (likedItemsCount) {
    const [firstPart, secondPart] = text.LOG_WITH_LIST;
    openLine = [firstPart, `(${String(likedItemsCount)})`, secondPart].join(
      ' ',
    );
  } else {
    openLine = text.LOG_EMPTY_LIST;
  }

  return [openLine, text.LOG_TO_LIKE].join(' ');
};

export const NoteRow = observer(function NoteRow({
  isLoggedIn,
  likedItemsCount,
}: Props) {
  return (
    <li className={styles.noteBox} data-nagish="favorites-no-items-note">
      <span className={styles.title}>{text.LIKED_ITEMS}</span>
      <span className={styles.text}>
        {isLoggedIn ? text.EMPTY_LIST : getNotLoggedInText(likedItemsCount)}
      </span>
    </li>
  );
});
