import { observer } from 'mobx-react-lite';
import { FavoriteItem } from '../../service';
import { FavoritesModel } from '../../models';
import { FavoritesDropItem } from '../favorites-drop-item';
import { NoteRow } from './note-row/note-row';
import { LinkRow } from './link-row/link-row';
import styles from './favorites-drop-list.module.scss';

type Props = {
  favorites: FavoritesModel;
  onRemoveItem: (item: FavoriteItem) => void;
  isLoggedIn?: boolean;
};

const MAX_ADS = 3;

export const FavoritesDropList = observer(function FavoritesList({
  favorites,
  onRemoveItem,
  isLoggedIn = false,
}: Props) {
  const showNoteBox = !isLoggedIn || !favorites.list.length;
  const list = [...favorites.list].reverse().slice(0, MAX_ADS);
  const { appName } = favorites;

  return (
    <ul className={styles.box} data-nagish="favorites-dropdown-list">
      {showNoteBox ? (
        <NoteRow
          isLoggedIn={isLoggedIn}
          likedItemsCount={favorites.list.length}
        />
      ) : (
        <>
          {list.map((item, index) => (
            <FavoritesDropItem
              key={item.id}
              item={item}
              onDislike={() => onRemoveItem(item)}
              appName={appName}
              index={index}
            />
          ))}
          <LinkRow />
        </>
      )}
    </ul>
  );
});
