import { Environment } from './schema';

export const environment: Environment = {
  name: 'production',
  dfpDevMode: false,
  routes: {
    api: {
      gw: 'https://gw.yad2.co.il',
      old: 'https://www.yad2.co.il/api',
    },
    mainSite: 'https://www.yad2.co.il',
    personal: 'https://personal.yad2.co.il',
    yadata: 'https://yadata.yad2.co.il',
    assets: 'https://assets.yad2.co.il',
    feedImages: 'https://img.yad2.co.il/Pic',
    appDownload: 'https://app-download.yad2.co.il',
  },
};
