/* eslint-disable no-underscore-dangle */
import { action, makeAutoObservable, observable } from 'mobx';
import { log } from '@y2/log';
import { getRecommendations } from './adapter/get-recommendations';
import { RecommendationItem } from './adapter/from-api-item';
import { toItemEvent } from './adapter/to-item-event';
import { EventAggregator } from '../../services/analytics/click-impression-encoding/event-aggregator';
import type { RecommendationsHomeCategory } from '@y2/recommendations';

export type Snapshot = RecommendationItem[];

export class RecommendationsModel {
  private _list!: RecommendationItem[];
  private _isMerchantCategory!: boolean;
  private _impressionsEvents!: EventAggregator;

  public constructor(initialState: Snapshot) {
    type PrivateMembers =
      | '_list'
      | '_impressionsEvents'
      | '_resetData'
      | '_isMerchantCategory';

    this._resetData(initialState, this.isMerchantCategory);
    makeAutoObservable<this, PrivateMembers>(this, {
      _list: observable.ref,
      _isMerchantCategory: observable.ref,
      _resetData: action.bound,
      update: action.bound,
      addImpression: action.bound,
      sendRemainingImpressions: action.bound,

      // no need to track _impressionsEvents
      _impressionsEvents: false,
    });
  }

  public get list() {
    return this._list;
  }

  public get isMerchantCategory() {
    return this._isMerchantCategory;
  }

  /**
   * updates the store latest searches state
   * @throws {never} logs errors and hide them from the UI
   */
  public async update(
    userId: string,
    homeCategory: RecommendationsHomeCategory,
  ) {
    try {
      const { data, isMerchantCategory } = await getRecommendations(
        userId,
        homeCategory,
      );
      this._resetData(data, isMerchantCategory);
    } catch (error) {
      log.error(
        error as Error,
        'failed to execute update at RecommendationsModel',
      );
    }
  }

  public addImpression(item: RecommendationItem) {
    this._impressionsEvents.addEvent(item.id);
  }

  public sendRemainingImpressions() {
    this._impressionsEvents.notifyInteraction();
  }

  /**
   * reset the list with a new data
   */
  private _resetData(data: Snapshot, isMerchantCategory: boolean) {
    this._list = data;
    this._isMerchantCategory = isMerchantCategory;
    this._impressionsEvents = new EventAggregator(data.map(toItemEvent));
  }
}
