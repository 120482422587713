/* eslint-disable max-lines-per-function */
import axios, { type ResponseBody } from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport } from '../helpers';
import { cancelTokenProvider } from '../helpers/cancel-token-provider';

export type AddressMasterSearchRes = {
  data: {
    data: Array<Record<string, string | number>>;
    message: string;
  };
};

type Location = {
  area_eng: string;
  area_heb: string;
  hood_eng: string;
  hood_heb: string;
  city_eng: string;
  city_heb: string;
  street_eng: string;
  street_heb: string;
  house_number: string;
  full_title_text: string;
};

export type AddressMasterNearestPointReq = {
  lat: number;
  lon: number;
  entity?: string;
  limit?: number;
};

export type AddressMasterAutoCompleteReq = {
  phrase: string;
  limit?: number;
  entities: Array<
    'top-areas' | 'areas' | 'cities' | 'hoods' | 'streets' | 'house-number'
  >;
};

type AddressMasterNearestPointRes = {
  data: {
    data: Location[];
    message: string;
  };
};

type AddressMasterAutoCompleteRes = {
  data: {
    data: {
      cities?: Location[];
    };
    message: string;
  };
};

export type AddressMasterArea = {
  top_area_heb: string;
  top_area_id: number;
  area_id: number;
  full_title_text: string;
};
export type AddressMasterAreasRes = {
  paginator: unknown;
  data: AddressMasterArea[];
};
interface CityItem {
  city_heb: string;
  city_id: string;
}

interface CityResult {
  data: { cities: CityItem[] };
  message: string;
}

interface StreetItem {
  street_heb: string;
  street_id: string;
  hood_id: number;
}

interface AddressPagination {
  lilmit: number;
  offset: number;
  total_pages: number;
  current_page: number;
  total_rows: number;
}

interface StreetResult {
  data: {
    data: StreetItem[];
    paginator: AddressPagination;
  };
  message: string;
}

export interface HouseNumberItem {
  address_master_id: string;
  house_number: string;
}

interface HouseNumberResult {
  data: {
    data: HouseNumberItem[];
    paginator: AddressPagination;
  };
  message: string;
}

export type AreasParams = {
  resultType?: string;
  limit?: number;
};
const serviceName = 'address-master';

/**
 * client for `address-master` service
 * @see https://gw.yad2.io/address-master/api-docs/#
 */
export const addressMasterClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });
  const { withCancelToken } = cancelTokenProvider();

  return {
    search: (
      searchText: string,
      entity?: string,
    ): Promise<AddressMasterSearchRes> =>
      withCancelToken((cancelToken) =>
        client.get(
          `/autocomplete/${searchText}${entity ? `?entities=${entity}` : ''}`,
          {
            cancelToken: cancelToken,
          },
        ),
      ),
    getNearestPoint: (
      params: AddressMasterNearestPointReq,
    ): Promise<AddressMasterNearestPointRes> =>
      client.get(`/nearest-point`, { params }),
    getAddressByAutoComplete: ({
      phrase,
      entities,
      limit = 5,
    }: AddressMasterAutoCompleteReq): Promise<AddressMasterAutoCompleteRes> =>
      client.get(
        `/autocomplete/${phrase}?limit=${limit}&entities=${entities.join(',')}`,
      ),

    getAllAreasInGroups: () =>
      client.get<ResponseBody<AddressMasterAreasRes>>(
        `/areas?result_type=extended&limit=1000&sort=nts`,
      ),
    getCities: (city: string) =>
      client.get<CityResult>(`autocomplete/${city}?entities=cities`),
    getStreet: (cityId: string, street: string) =>
      client.get<StreetResult>(
        `streets?page=1&limit=10&result_type=auto-complete&street_heb=${street}&city_id=${cityId}`,
      ),
    getHouse: (cityId: string, streetId: string, number: string) =>
      client.get<HouseNumberResult>(
        `house-number?page=1&limit=15&result_type=auto-complete&house_number=${number}&street_id=${streetId}&city_id=${cityId}`,
      ),
    getEntrance: (cityId: string, streetId: string, number: string) =>
      client.get(
        `entrance?house_number=${number}&street_id=${streetId}&city_id=${cityId}`,
      ),
    getArea: (cityId: string) =>
      client.get(`cities/${cityId}?result_type=extended`),
    getHood: (cityId: string, streetId: string, number: string) =>
      client.get(
        `house-number?result_type=extended&city_id=${cityId}&street_id=${streetId}&house_number=${number}&limit=10&page=1`,
      ),
    getTotalStreets: (cityId: string) =>
      client.get<StreetResult>(`streets?city_id=${cityId}&limit=1`),
  };
};

export type AddressMasterClient = ReturnType<typeof addressMasterClient>;
