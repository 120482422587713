/* eslint-disable @typescript-eslint/no-namespace */
import axios, { ResponseBody } from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport } from '../helpers';
import { categories } from '@y2/categories';
import type { VehicleAd } from './vehicles-item';
import type { RealestateAd } from './realestate-item';
import { ProductsAd } from './item';

export namespace RecommendationsApi {
  export type ItemsRequestBody = {
    categoryId: number;
    subCategoriesIds?: number[] | number;
    type: string;
    count: number;
    getSecondBatch?: boolean;
    itemId?: string;
    userId?: string;
    startAt?: number;
    boostCategory?: boolean;
    boostCategoryValue?: number;
    boostMerchant?: boolean;
    boostMerchantValue?: number;
    typeRequest?: 'default';
  };

  export type RealestateItemsRequestBody = Omit<
    ItemsRequestBody,
    'subCategoriesIds'
  > & {
    subCategoriesIds: string;
    roomValues?: string;
    propertyValues?: string;
    cityValues?: string;
  };

  type ProductReqBodyBase = {
    count?: number;
    subCategoriesIds?: string;
    boostCategory?: boolean;
    boostCategoryValue?: number;
    startAt?: number;
    typeRequest?: string;
  };

  export type ProductsHomeReqBody = ProductReqBodyBase & {
    type: 'home';
  };

  export type ProductsItemReqBody = ProductReqBodyBase & {
    type: 'item';
    itemId: string;
  };

  export type ProductsRequestBody = ProductsHomeReqBody | ProductsItemReqBody;

  export type ItemsResponseBody = {
    message: string;
    data: [RecommendationItem[]];
  };

  export type EventRequestBody = {
    eventName: EventName;
    itemId: string;
    categoryId:
      | typeof categories.vehicles.id
      | typeof categories.realEstate.id
      | typeof categories.products.id;
    subCategoryId?: number;
    userId?: string;
    type?: EventOrigin;
    widgetId?: number;
  };

  export type EventResponseBody = {
    message: string;
    data: Record<string, string>;
  };

  export type EventName = 'view' | 'viewReco' | 'buy';
  export type EventOrigin = 'home' | 'item';

  type ItemBase = {
    id: string;
    image_urls: string[];
    price: string;
    merchant: boolean;
    ad_highlight_type: string;
  };

  export type ProductsItem = ItemBase & {
    row_1: string;
    city: string;
  };

  export type VehicleItem = ItemBase & {
    manufacturer: string;
    year: number;
    model: string;
    is_trade_in_button: boolean;
  };

  export type RealEstateItemBase = ItemBase & {
    row_1: string;
    square_meters: number;
    city: string;
  };

  type Rooms = {
    rooms: number | null;
  };

  export type RealEstateCommercialItem = RealEstateItemBase;
  export type RealEstateSaleItem = RealEstateItemBase & Rooms;
  export type RealEstateRentItem = RealEstateItemBase & Rooms;

  export type RecommendationItem =
    | ProductsItem
    | VehicleItem
    | RealEstateCommercialItem
    | RealEstateSaleItem
    | RealEstateRentItem;
}

export type RecommendationVehiclesResponse = [VehicleAd[], VehicleAd[]];
export type RecommendationRealestateResponse = [RealestateAd[]];
export type RecommendationProductsResponse = [ProductsAd[]];

const serviceName = 'recommendations';

/**
 * client for `recommendations` service
 * @see https://gw.yad2.io/recommendations/api-docs/#
 */
export const recommendationsClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });

  return {
    get: (reqDetails: RecommendationsApi.ItemsRequestBody) =>
      client.post<RecommendationsApi.ItemsResponseBody>('/items', reqDetails),
    post: (reqDetails: RecommendationsApi.EventRequestBody) =>
      client.post<RecommendationsApi.EventResponseBody>('/event', reqDetails),
    getVehicles: (reqDetails: RecommendationsApi.ItemsRequestBody) =>
      client.get<ResponseBody<RecommendationVehiclesResponse>>(
        '/items/vehicles',
        {
          params: reqDetails,
        },
      ),
    getRealestate: (
      reqDetails: RecommendationsApi.RealestateItemsRequestBody,
    ) =>
      client.get<ResponseBody<RecommendationRealestateResponse>>(
        '/items/realestate',
        {
          params: reqDetails,
        },
      ),
    getProducts: (reqDetails: RecommendationsApi.ProductsRequestBody) =>
      client.get<ResponseBody<RecommendationProductsResponse>>(
        '/items/products',
        {
          params: {
            categoryId: categories.products.id,
            ...reqDetails,
          },
        },
      ),
  };
};
