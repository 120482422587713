import { reverseMapping } from './reverse-mapping';

/**
 * creates encoding from a mapping of values to codes
 * @param mapping don't forget to specify `as const` if you want the result to be from your specific keys instead of `string`
 * @returns methods to encode and decode
 *
 * @example
 * // prettier-ignore
 * const adTypeEncoding = createMapEncoding({
 *   'Private': '0',
 *   'Business': '1',
 *   'Yad1': '2',
 * } as const);
 *
 * encode('Private')  // => '0'
 * encode('Business') // => '1'
 * encode('Yad1')     // => '2'
 *
 * decode('0')        // => 'Private'
 * decode('1')        // => 'Business'
 * decode('2')        // => 'Yad1'
 */
export function createMapEncoding<K extends string, V extends string>(
  mapping: Record<K, V>,
) {
  const reversedMapping = reverseMapping(mapping);

  const encode = (value: K): V => mapping[value];

  const decode = (code: string): K | undefined =>
    reversedMapping[code as V] ?? undefined;

  const values = Object.keys(mapping);

  return {
    encode,
    decode,
    values,
  };
}

/**
 * creates encoding from boolean to code
 * @returns methods to encode and decode
 *
 * @example
 * const {encode, decode} = createBooleanEncoding()
 *
 * encode(false)  // => '0'
 * encode(true)   // => '1'
 *
 * decode('0')    // => false
 * decode('1')    // => true
 */
export function createBooleanEncoding() {
  const encodedMapping = {
    '0': false,
    '1': true,
  } as const;

  type EncodedBool = keyof typeof encodedMapping;

  const encode = (value: boolean): EncodedBool => (value ? '1' : '0');

  const decode = (code: string): boolean | undefined =>
    encodedMapping[code as EncodedBool] ?? undefined;

  return {
    encode,
    decode,
  };
}
