/* eslint-disable no-underscore-dangle */
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { Config, FavoriteItem, FavoritesService } from '../service';

export type Snapshot = {
  list: FavoriteItem[];
  initialLoading: boolean;
  appName: string;
};

export class FavoritesModel {
  private _service!: FavoritesService;
  private _list!: FavoriteItem[];
  private _optimisticUpdate!: Set<string>;
  private _initialLoading!: boolean;
  private _appName!: string;

  public constructor(config: Config, initialState: Snapshot) {
    type PrivateMembers = '_service' | '_resetData' | '_list';
    this._list = initialState.list;
    this._initialLoading = initialState.initialLoading;
    this._appName = initialState.appName;
    this._service = new FavoritesService(config);
    this._optimisticUpdate = new Set(initialState.list.map((item) => item.id));

    makeAutoObservable<FavoritesModel, PrivateMembers>(this, {
      _service: false,
      deleteItem: action.bound,
      fetchAnonymous: action.bound,
      syncAndFetch: action.bound,
      addItem: action.bound,
      _resetData: action.bound,
      _list: observable.shallow,
    });
  }

  public get list() {
    return this._list;
  }

  public get initialLoading() {
    return this._initialLoading;
  }

  public get appName() {
    return this._appName;
  }

  public includes(id: string) {
    return this._optimisticUpdate.has(id);
  }

  public async deleteItem(isLoggedIn: boolean, id: string) {
    this._optimisticUpdate.delete(id);
    await this._service.remove(isLoggedIn, id);
    runInAction(() => {
      this._list = this._list.filter((item) => item.id !== id);
    });
  }

  public async addItem(isLoggedIn: boolean, id: string) {
    if (this._optimisticUpdate.has(id)) {
      // do not add items if they already exist
      return;
    }
    this._optimisticUpdate.add(id);
    const newItem = await this._service.add(isLoggedIn, id);
    runInAction(() => {
      this._list.push(newItem);
    });
  }

  public async fetchAnonymous() {
    try {
      const data = await this._service.getAllAnonymous();
      this._resetData(data);
    } finally {
      runInAction(() => {
        this._initialLoading = false;
      });
    }
  }

  public async syncAndFetch() {
    try {
      await this._service.flushLocalToRemote();
      const data = await this._service.getAllLoggedUser();
      this._resetData(data);
    } finally {
      runInAction(() => {
        this._initialLoading = false;
      });
    }
  }

  private _resetData(data: Snapshot['list']) {
    this._list = data;
    this._optimisticUpdate = new Set(data.map((item) => item.id));
    this._initialLoading = false;
  }
}
