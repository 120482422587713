import { categories } from '@y2/categories';
export type Category = keyof typeof CATEGORIES;

export const CATEGORIES = {
  [categories.realEstate.title]: {
    title: categories.realEstate.titleHeb.replace('"', ''),
    categoryId: categories.realEstate.id,
  },
  [categories.vehicles.title]: {
    title: categories.vehicles.titleHeb,
    categoryId: categories.vehicles.id,
  },
  [categories.products.title]: {
    title: categories.products.titleHeb,
    categoryId: categories.products.id,
  },
};
