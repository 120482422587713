import styles from './publish-ad.module.scss';
import { AddIcon } from '@y2/mango/components/icons';
import texts from './texts.json';
import env from '@y2/environments';
import { IconButton } from '@y2/mango/components/button';
import cn from 'classnames';
import noop from 'lodash/noop';

type Props = {
  onClick?: () => void;
  className?: string;
};

export const PublishAdLink = ({ onClick = noop, className }: Props) => (
  <IconButton
    variant="secondary"
    onClick={onClick}
    asElement="a"
    href={`${env.routes.personal}/publish`}
    iconPosition={'right'}
    iconSlot={<AddIcon />}
    mobileOnlySize="medium"
    desktopOnlySize="small"
    className={cn(styles.button, className)}
  >
    {texts.publishAd}
  </IconButton>
);
