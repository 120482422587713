import { StoreProvider, createStore, initialState } from '../services/store';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { setupMobx } from '@y2/mobx';
import { CATEGORIES_LIST, Footer } from '@y2/footer';
import { ErrorBoundary } from 'react-error-boundary';
import { BoundaryFallback } from './_error.page';
import { Header } from '../features/header';
import { useExposeApi } from '../features/expose-api';
import { environment } from '../environments/environment';
import { GoogleTagManagerScript } from '@y2/google-tag-manager';
import { getAnalyticsEnv } from '../features/analytics';
import '@y2/mango/fonts';
import './global-styles.scss';
import { DfpProvider } from '@y2/deprecated/dfp';
import { Accessibility } from '@y2/accessibility';
import { NanorepChat } from '@y2/nanorep-chat';
import env from '@y2/environments';
import styles from './app.module.scss';
import { CohortSetup } from '@y2/cohort';
import { BrowserSupport } from '@y2/browser-support';
import { BrazeInitializer } from '../components/braze-initializer/braze-initializer';
import { GlassixChatBubble } from '@y2/glassix-chat-bubble';
import { BrazeProvider } from '@y2/braze';
import { usePushUuidEventOnLoad } from '@y2/google-tag-manager/update-uuid-event/use-push-uuid-on-load';
// import { MemcycoScript } from '../components/memcyco-script/memcyco-script';

setupMobx();
const store = createStore(initialState);

function CustomApp({ Component, pageProps }: AppProps) {
  useExposeApi({ disabled: environment.name !== 'local' });
  usePushUuidEventOnLoad();

  return (
    <StoreProvider value={store}>
      <Head>
        <meta name="cHash" content={env.commit} />
      </Head>
      <DfpProvider isDevMode={environment.dfpDevMode}>
        <BrazeProvider>
          <div className="app">
            <Header className={styles.header} />
            <div className={styles.headerPlaceholder} />
            <ErrorBoundary FallbackComponent={BoundaryFallback}>
              <Component {...pageProps} />
            </ErrorBoundary>
            <Footer
              env={environment.name}
              categoriesList={CATEGORIES_LIST}
              aboutText=""
            />
          </div>
          <BrazeInitializer />
        </BrazeProvider>
      </DfpProvider>
      <Accessibility />
      <GoogleTagManagerScript environment={getAnalyticsEnv(environment.name)} />
      {/* <MemcycoScript /> */}
      <NanorepChat />
      <GlassixChatBubble />
      <CohortSetup />
      <BrowserSupport />
    </StoreProvider>
  );
}

export default CustomApp;
