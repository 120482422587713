/* eslint-disable camelcase */

import {
  NearMeAd,
  RealEstateAd,
  SecondHandAd,
  VehicleAd,
} from '@y2/api-clients/carousels';
import { categories } from '@y2/categories';

type BaseAdItem = {
  id: string;
  categoryId: number;
  subcategoryId: number;
  price: number;
  image: string;
};

export type RealEstateAdItem = BaseAdItem & {
  city?: string;
  street?: string;
  houseNumber?: number;
  rooms: number;
};

export type SecondHandAdItem = BaseAdItem & {
  city?: string;
  description: string;
};

export type VehicleAdItem = BaseAdItem & {
  manufacturer?: string;
  model?: string;
  year: number;
};

export type NearMeAdItem = SecondHandAdItem | RealEstateAdItem | VehicleAdItem;

/**
 * transforms item that has been retrieved from request to a categorized item by category_id
 * @throws {Error} when `category_id` is invalid
 */
export const getNearMeAdItemByCategory = (item: NearMeAd) => {
  const { token, category_id, subcategory_id, price, meta_data } = item;
  const baseAdItem: BaseAdItem = {
    id: token,
    categoryId: category_id,
    subcategoryId: subcategory_id,
    price,
    image: meta_data.cover_image,
  };

  if (category_id === categories.vehicles.id) {
    return getNearMeVehicleAdItem(item, baseAdItem);
  } else if (category_id === categories.realEstate.id) {
    return getNearMeRealEstateAdItem(item, baseAdItem);
  } else if (category_id === categories.products.id) {
    return getNearMeSecondHandAdItem(item, baseAdItem);
  }

  throw new Error(`category_id \`${category_id}\` is not supported.`);
};

const getNearMeVehicleAdItem = (
  item: NearMeAd,
  baseAdItem: BaseAdItem,
): VehicleAdItem => {
  const { search_fields } = item as VehicleAd;
  return {
    ...baseAdItem,
    manufacturer: search_fields.manufacturer?.text,
    model: search_fields.model?.text,
    year: search_fields.year,
  };
};

const getNearMeRealEstateAdItem = (
  item: NearMeAd,
  baseAdItem: BaseAdItem,
): RealEstateAdItem => {
  const { address, search_fields } = item as RealEstateAd;
  return {
    ...baseAdItem,
    city: address.city?.text,
    street: address.street?.text,
    houseNumber: address.house?.number,
    rooms: search_fields.rooms_count,
  };
};

function getNearMeSecondHandAdItem(
  item: NearMeAd,
  baseAdItem: BaseAdItem,
): SecondHandAdItem {
  const { address, meta_data } = item as SecondHandAd;
  return {
    ...baseAdItem,
    city: address.city?.text,
    description: meta_data.description,
  };
}
