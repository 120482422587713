/* eslint-disable camelcase */

import { RecommendationsApi } from '@y2/api-clients/recommendations';

type ItemBase = {
  id: string;
  images: string[];
  price: string;
  merchant: boolean;
  adHighlightType: 'none' | 'yellow' | 'pink';
};

type VehicleItem = ItemBase & {
  type: 'vehicle';
  manufacturer: string;
  model: string;
  year: number;
  isTradeIn: boolean;
};

type ProductItem = ItemBase & {
  type: 'product';
  product: string;
  area: string;
};

type RealEstateItemBase = ItemBase & {
  squareMeters: number;
  area: string;
};

type RealEstateCommercialItem = RealEstateItemBase & {
  type: 'realEstate/commercial';
};

type Rooms = {
  rooms: number | null;
};

type RealEstateSaleItem = RealEstateItemBase &
  Rooms & {
    type: 'realEstate/sale';
  };

type RealEstateRentItem = RealEstateItemBase &
  Rooms & {
    type: 'realEstate/rent';
  };

export type RecommendationItem =
  | VehicleItem
  | ProductItem
  | RealEstateCommercialItem
  | RealEstateSaleItem
  | RealEstateRentItem;

type FromApiItem = (
  type: RecommendationItem['type'],
) => (rawItem: RecommendationsApi.RecommendationItem) => RecommendationItem;

/**
 * transforms raw recommendation api item to a more sane item to work with
 * @param type expected item type
 */
export const fromApiItem: FromApiItem = (type) => (rawItem) => {
  const { id, image_urls, merchant, price, ad_highlight_type } = rawItem;
  const baseItem: ItemBase = {
    id,
    images: image_urls,
    price,
    merchant,
    adHighlightType: ad_highlight_type as ItemBase['adHighlightType'],
  };

  if (type === 'vehicle') {
    return fromApiVehicleItem(rawItem, baseItem);
  } else if (type === 'product') {
    return fromApiProductItem(rawItem, baseItem);
  } else {
    return fromApiRealEstateItem(type, rawItem, baseItem);
  }
};

function fromApiVehicleItem(
  rawItem: RecommendationsApi.RecommendationItem,
  baseItem: ItemBase,
): VehicleItem {
  const { manufacturer, model, year, is_trade_in_button } =
    rawItem as RecommendationsApi.VehicleItem;
  return {
    ...baseItem,
    type: 'vehicle',
    manufacturer,
    model,
    year,
    isTradeIn: Boolean(is_trade_in_button),
  };
}

function fromApiProductItem(
  rawItem: RecommendationsApi.RecommendationItem,
  baseItem: ItemBase,
): ProductItem {
  const { row_1, city } = rawItem as RecommendationsApi.ProductsItem;
  return {
    ...baseItem,
    type: 'product',
    product: row_1,
    area: city,
  };
}

function fromApiRealEstateItem(
  type: RecommendationItem['type'],
  rawItem: RecommendationsApi.RecommendationItem,
  baseItem: ItemBase,
): RealEstateCommercialItem | RealEstateSaleItem | RealEstateRentItem {
  const { square_meters, row_1, city } =
    rawItem as RecommendationsApi.RealEstateItemBase;
  const realEstateItem = {
    ...baseItem,
    area: row_1 === city ? city : [city, row_1].join(', '),
    squareMeters: square_meters,
  };
  if (type === 'realEstate/rent' || type === 'realEstate/sale') {
    const { rooms } = rawItem as
      | RecommendationsApi.RealEstateSaleItem
      | RecommendationsApi.RealEstateRentItem;
    return {
      ...realEstateItem,
      type,
      rooms,
    };
  } else {
    const { square_meters } =
      rawItem as RecommendationsApi.RealEstateCommercialItem;
    return {
      ...realEstateItem,
      type: 'realEstate/commercial',
      squareMeters: square_meters,
    };
  }
}

export const isPriceIncluded = (priceText: string) =>
  priceText !== 'לא צוין מחיר';
