import React, { HTMLAttributes } from 'react';
import styles from './option-icon.module.scss';
import Option from '../option/option';
import cn from 'classnames';
import { HeartIcon } from '@y2/mango/components/icons';

type Props = HTMLAttributes<HTMLElement> & {
  onClick?: () => void;
  count?: number;
};

export const FavoritesInlineButton = ({
  onClick,
  count,
  ...otherAttributes
}: Props) => (
  <Option
    {...otherAttributes}
    icon={<HeartIcon className={cn(styles.icon)} />}
    url={'/my-favorites'}
    onClick={onClick}
    {...(count ? { count } : {})}
    countOnIcon
    className={styles.inlineButton}
    data-testid="favorites-header-option"
  />
);
