/* eslint-disable custom-rules/user-agent */
/* eslint-disable camelcase */
import { cookie } from '@y2/packages-utils/cookies';
import {
  AB_TEST_KEY,
  FAVORITES_USER_KEY,
  Y2_COHORT_2020,
  AB_TEST_FALLBACK_ID,
  Y2_COHORT_2018,
  COHORT_NOT_EXIST,
} from './consts';

const getPlatformDevice = (): string => {
  let platformType = 'other';
  if (/Android/i.test(window.navigator.userAgent)) {
    platformType = 'android';
  } else if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
    platformType = 'iOS';
  }
  return platformType;
};

export const getUserId = (): { user_id?: string } => {
  const user_id = cookie.get(FAVORITES_USER_KEY);
  return user_id ? { user_id } : {};
};

export const getPlatformParams = (deviceName: string, isMobile: boolean) => ({
  AppType: `${deviceName}_`,
  zonePrefix: `${deviceName}_/`,
  WebPlatformVersion: `mobileweb-5.00`,
  ...(isMobile ? { platformType: `${getPlatformDevice()}_mobileweb` } : {}),
});

export const getAbTest = () => ({
  abtest: `${cookie.get(AB_TEST_KEY) || AB_TEST_FALLBACK_ID}`,
});

export const getCohortParams = (deviceName: string) => ({
  Cohort: `${deviceName}_${cookie.get(Y2_COHORT_2018) || COHORT_NOT_EXIST}`,
  new_cohort: `${deviceName}_${cookie.get(Y2_COHORT_2020) || COHORT_NOT_EXIST}`,
});
