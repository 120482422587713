import { useState, useRef } from 'react';
import cn from 'classnames';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import styles from './favorites-dropdown.module.scss';
import noop from 'lodash/noop';
import { FavoritesDropList } from '../favorites-drop-list';
import { FavoritesModel } from '../../models';
import { FavoriteItem } from '../../service';
import { FavoritesDropButton } from './favorites-drop-butoon/favorites-drop-button';
import { usePopupViewportPosition } from '@y2/hooks/use-popup-viewport-position';

type Props = {
  favorites: FavoritesModel;
  isLoggedIn: boolean;
  onRemoveItem?: () => void;
  onClick?: () => void;
  className?: string;
};

export const FavoritesDropDown = observer(function FavoritesDropDown({
  favorites,
  isLoggedIn,
  className,
  onRemoveItem = noop,
  onClick = noop,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropRef = useRef<HTMLDivElement>(null);
  usePopupViewportPosition(dropRef, isOpen);
  const handleRemoveItem = (item: FavoriteItem) => {
    onRemoveItem();
    favorites.deleteItem(isLoggedIn, item.id);
  };

  return (
    <div
      className={cn(styles.box, className)}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <FavoritesDropButton likedCount={favorites.list.length} click={onClick} />
      <div
        className={cn(styles.dropContent, isOpen && styles.open)}
        ref={dropRef}
      >
        <FavoritesDropList
          onRemoveItem={action(handleRemoveItem)}
          favorites={favorites}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </div>
  );
});
