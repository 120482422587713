import { ChatIcon } from '@y2/mango/components/icons';
import { ChatMessageNotification } from '@y2/socket-clients/sendbird-updates/types';
import cn from 'classnames';
import { HTMLAttributes, useState } from 'react';
import Option from '../option/option';
import styles from './inline-chat-button.module.scss';
import { IncomingSnackbar } from '@y2/chat-common-ui';
import { useUnreadChatMessagesCount } from '../../hooks';
import text from './inline-chat-button.text.json';
import noop from 'lodash/noop';

type Props = HTMLAttributes<HTMLElement> & {
  isUserLoggedIn: boolean;
  onClick?: () => void;
  sendAnalytics?: () => void;
  onNewMessage?: (message: ChatMessageNotification) => void;
};

const CHAT_INBOX_URL = '/chat/v2/inbox?serviceInitiator=Header';

export const InlineChatButton = ({
  isUserLoggedIn,
  onClick = noop,
  sendAnalytics,
  onNewMessage = noop,
  ...otherAttributes
}: Props) => {
  const [currMessage, setCurrMessage] = useState<ChatMessageNotification>();

  const onNewMessageCallBack = (message: ChatMessageNotification) => {
    onNewMessage(message);
    setCurrMessage(message);
  };
  const unreadChatMessagesCount = useUnreadChatMessagesCount(
    isUserLoggedIn,
    onNewMessageCallBack,
  );

  return (
    <>
      <Option
        {...otherAttributes}
        icon={<ChatIcon className={cn(styles.icon)} />}
        url={CHAT_INBOX_URL}
        onClick={onClick}
        sendAnalytics={sendAnalytics}
        count={unreadChatMessagesCount}
        countOnIcon
        className={styles.inlineButton}
        data-testid="inline-chat-button"
      >
        <span className={styles.text}>{text.chat}</span>
      </Option>
      <IncomingSnackbar message={currMessage} />
    </>
  );
};
