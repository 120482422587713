import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../services/store';
import { BrazeSetup } from '@y2/braze';

export const BrazeInitializer = observer(function UserDropdown() {
  const store = useStore();
  const { currentUser, loading } = store.auth;

  return <BrazeSetup uuidFromFetch={currentUser?.uuid} isFetching={loading} />;
});
