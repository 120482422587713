import { AddressMasterNearestPointReq } from '@y2/api-clients/address-master';
import * as api from '../../../services/api';

/**
 * fetches user's nearest city based on his current location's coordinations
 * @see spec https://gw.yad2.io/address-master/api-docs/#/Nearest%20Point/get_nearest_point
 * @returns user's nearest city based on his current location's coordinations
 * @throws {Error} when failed to fetch 'city' or error from request to the api get-nearest-point
 */
export async function getNearestCity(
  addressMasterNearestPointReq: AddressMasterNearestPointReq,
): Promise<string> {
  const res = await api.addressMaster.getNearestPoint({
    ...addressMasterNearestPointReq,
    entity: 'cities',
    limit: 1,
  });

  const city = res.data?.data?.[0]?.city_heb;
  if (!city) {
    throw new Error('failed to fetch city.');
  }

  return res.data?.data?.[0]?.city_heb;
}
