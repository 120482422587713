import * as mobx from 'mobx';
import { verifyMobxTranspilerConfig } from './verify-mobx-transpiler-config';
import { enableStaticRendering } from 'mobx-react-lite';

const isSSR = typeof window === 'undefined';

export function setupMobx() {
  verifyMobxTranspilerConfig();
  enableStaticRendering(isSSR);

  mobx.configure({
    useProxies: 'always',
    enforceActions: 'always',
    computedRequiresReaction: !isSSR,
    reactionRequiresObservable: false,
    observableRequiresReaction: !isSSR,
  });
}
