import type { Environment } from '../../environments/schema';

type AnalyticsEnvironment = 'production' | 'dev';

type AppEnvironmentName = Environment['name'];

const mapping: Record<AppEnvironmentName, AnalyticsEnvironment> = {
  dev: 'dev',
  staging: 'dev',
  production: 'production',
};

export const getAnalyticsEnv = (envName: AppEnvironmentName) =>
  mapping[envName];
