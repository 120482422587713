type Hooks = () => void;

interface IBeforeInitHooks {
  hooks: Hooks[];
  onDfpInit(onInitHook: Hooks): void;
  emptyHooksArr(): void;
  executeHooks(): void;
}
export const beforeInitHooks: IBeforeInitHooks = {
  hooks: [],

  onDfpInit: (onInitHook) => {
    beforeInitHooks.hooks.push(onInitHook);
  },

  emptyHooksArr: () => {
    /* eslint-disable-next-line no-magic-numbers */
    beforeInitHooks.hooks.splice(0, beforeInitHooks.hooks.length);
  },

  executeHooks: () => {
    beforeInitHooks.hooks.forEach((hook) => hook());
    beforeInitHooks.emptyHooksArr();
  },
};
