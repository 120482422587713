import { useContext, useEffect } from 'react';
import { DfpContext } from './dfp-context/dfp-context-provider';
import { DfpState } from './dfp-context/dfp-state';
import {
  getAbTest,
  getCohortParams,
  getPlatformParams,
  getUserId,
} from './targeting-params-helpers';

export const getPageTargetingParams = (
  isMobile: boolean,
  context: DfpState,
): Record<string, number | string | boolean> => {
  const initParams = getInitParams(isMobile);
  if (Object.keys(context.pageTargetingParams).length === 0) {
    // eslint-disable-next-line no-console
    console.warn('failed to load dfp due to missing route targeting from app');
  }
  return {
    ...initParams,
    ...context.pageTargetingParams,
  };
};

export const getInitParams = (isMobile: boolean) => {
  const deviceName = isMobile ? 'Mobile' : 'Desktop';
  return {
    ...getAbTest(),
    ...getCohortParams(deviceName),
    ...getUserId(),
    ...getPlatformParams(deviceName, isMobile),
  };
};

export const usePageMediaTargeting = (
  pageTargetingParams: Record<string, number | string | boolean>,
) => {
  const dfpContext = useContext(DfpContext);
  useEffect(() => {
    if (dfpContext) {
      dfpContext.pageTargetingParams = pageTargetingParams;
    }
  }, [pageTargetingParams, dfpContext]);
};
