import type { ItemEventData } from '../../../services/analytics/click-impression-encoding/encoding';
import { isPriceIncluded, RecommendationItem } from './from-api-item';

// prettier-ignore
const colorMapping = {
  'none': 'Grey',
  'yellow': 'Yellow',
  'pink': 'Pink'
} as const;

export function toItemEvent(
  item: RecommendationItem,
  index: number,
): ItemEventData {
  return {
    id: item.id,
    adType: item.merchant ? 'Business' : 'Private',
    color: colorMapping[item.adHighlightType],
    withImage: item.images.length > 0,
    withVideo: false,
    price: isPriceIncluded(item.price) ? 'With price' : 'Without price',
    locationType: 'Recommendation Engine - Home page/main search',
    locationIndex: index + 1,
  };
}
