import { dfpEvents } from './consts';
import { DfpState } from './dfp-context/dfp-state';
import type { GoogleEventSlot, GoogleSlot, Slot } from './dfp-context/types';
import { isMobileDevice } from './utils';
import { getPageTargetingParams } from './page-targeting-utils';

export const refreshSlot = (slot: unknown) => {
  window.googletag.cmd.push(function () {
    window.googletag.pubads().refresh([slot]);
  });
};

export const manageTargeting = (context: DfpState) => {
  const isMobile = isMobileDevice();
  window.googletag.pubads().clearTargeting();
  setTargeting(isMobile, context);
};

export const displayAds = (adSlots: Slot[]) => {
  adSlots.forEach(({ elementId }) => {
    window.googletag.cmd.push(() => {
      window.googletag.display(elementId);
    });
  });
};

export const isAdAlreadyDefined = (slotID: string) => {
  const slots: GoogleSlot[] = window.googletag.pubads().getSlots();
  if (
    slots.find(
      (googleSlot: GoogleSlot) => googleSlot.getSlotElementId() === slotID,
    )
  ) {
    // eslint-disable-next-line no-console
    console.warn(`${slotID} slot already defined`);
  }
};

export const destroySlot = (elementId: string) => {
  const slots: GoogleSlot[] = window.googletag.pubads().getSlots();
  const slot = slots.find(
    (googleSlot: GoogleSlot) => googleSlot.getSlotElementId() === elementId,
  );
  window.googletag.cmd.push(() => {
    const wasSuccessfull = window.googletag.destroySlots([slot]);
    if (!wasSuccessfull) {
      // eslint-disable-next-line no-console
      console.warn(`${elementId} has not destoyed`);
    }
  });
};

export const defineAds = (googleAdSlots: Slot[], context: DfpState) => {
  googleAdSlots.forEach(({ path, size, elementId, slotCallBacks }) => {
    isAdAlreadyDefined(elementId);
    window.googletag.cmd.push(() => {
      window.googletag
        .defineSlot(path, size, elementId)
        ?.addService(window.googletag.pubads());
    });
    slotCallBacks && context.saveCallBack(elementId, slotCallBacks);
  });
  window.googletag.pubads().enableSingleRequest();
  window.googletag.enableServices();
};

export const appendEventListneresOnInit = (context: DfpState) => {
  if (!window) {
    // eslint-disable-next-line no-console
    console.warn(
      'DFP eventListners not appended window is undefined, dfp should run in SSR ',
    );
  }
  window.googletag = window.googletag || { cmd: [] };
  window.googletag.cmd.push(() =>
    dfpEvents.forEach((dfpEvent) => {
      window.googletag
        .pubads()
        .addEventListener(dfpEvent, ({ slot }: GoogleEventSlot) => {
          const slotId: string = slot.getSlotElementId();
          const slotcallback = context.savedCallBack.find(
            // eslint-disable-next-line max-nested-callbacks
            (slot) =>
              slot.slotID === slotId && slot.callBack.triggedEvent === dfpEvent,
          );
          slotcallback && slotcallback.callBack.savedCallBack();
        });
    }),
  );
};

const setTargeting = (isMobile: boolean, context: DfpState) => {
  const params = getPageTargetingParams(isMobile, context);
  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'object') {
      window.googletag.pubads().setTargeting(
        key,
        Object.values(params[key]).map((value) => `${value}`),
      );
    } else {
      window.googletag.pubads().setTargeting(key, `${params[key]}`);
    }
  });
};
