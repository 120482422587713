import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { FavoriteItem } from '../../../service';
import styles from './item-info-column.module.scss';
import text from './item-info-column.text.json';

type Props = {
  item: FavoriteItem;
  isPrice?: boolean;
};

export const ItemInfoColumn = observer(function ItemInfoColumn({
  item,
  isPrice = false,
}: Props) {
  const typeClass = isPrice ? styles.priceCol : styles.infoCol;
  return (
    <span className={cn(styles.col, typeClass)}>
      {isPrice ? (
        item.price || text.NO_PRICE_MENTIONED
      ) : (
        <>
          <span className={styles.row}>{item.title}</span>
          <span className={cn(styles.row, styles.description)}>
            {item.subTitle || item.comment}
          </span>
        </>
      )}
    </span>
  );
});
