/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { CallBack } from '../dfp-item/types';
import { SavedCallBack, Slot } from './types';

export class DfpState {
  private _isDevMode: boolean;
  private _adSlots: Slot[];
  private _savedCallBack: SavedCallBack[];
  private _roadBlockSlots: Slot[];
  private _debounce: any;
  private _roadBlockDebounce: any;
  private _pageTargetingParams: Record<string, string | number | boolean>;

  constructor(isDevMode: boolean) {
    this._isDevMode = isDevMode;
    this._adSlots = [];
    this._savedCallBack = [];
    this._roadBlockSlots = [];
    this._debounce = null;
    this._roadBlockDebounce = null;
    this._pageTargetingParams = {};
  }

  get isDevMode() {
    return this._isDevMode;
  }

  get adSlots() {
    return this._adSlots;
  }

  get roadBlockSlots() {
    return this._roadBlockSlots;
  }

  get savedCallBack() {
    return this._savedCallBack;
  }

  get debounce() {
    return this._debounce;
  }

  set debounce(newTimeout) {
    this._debounce = newTimeout;
  }

  get roadBlockDebounce() {
    return this._roadBlockDebounce;
  }

  set roadBlockDebounce(newRoadBlockTimeout) {
    this._roadBlockDebounce = newRoadBlockTimeout;
  }

  get pageTargetingParams() {
    return this._pageTargetingParams;
  }

  set pageTargetingParams(newPageTargetingParams) {
    this._pageTargetingParams = newPageTargetingParams;
  }

  addSlotToRoadBlockArray(adSlot: Slot) {
    this._roadBlockSlots.push(adSlot);
  }

  addSlotToAdSlots(adSlot: Slot) {
    this._adSlots.push(adSlot);
  }

  destoySlotEventsCallback(elementId: string) {
    this._savedCallBack = this._savedCallBack.filter(
      (slot) => slot.slotID !== elementId,
    );
  }

  clearAdSlotsArray(adSlots: Slot[]) {
    this._adSlots = this._adSlots.filter(
      (slot) =>
        !adSlots.find((dfpSlot) => dfpSlot.elementId === slot.elementId),
    );
  }

  saveCallBack(slotID: string, slotCallBack: CallBack[]) {
    if (slotCallBack && slotCallBack.length) {
      slotCallBack.forEach((callBack) => {
        this._savedCallBack.push({ slotID, callBack });
      });
    }
  }

  clearRoadBlockSlots() {
    this._roadBlockSlots = [];
  }
}
