import {
  CarouselsNearMeAdsReq,
  CarouselsNearMeVehicleAdsReq,
} from '@y2/api-clients/carousels';
import { Location } from '../location/location';
import { CATEGORIES, Category } from './categories';
import { getNearMeVehicleAds } from './adapter/get-near-me-vehicle-ads';
import { getNearMeAds } from './adapter/get-near-me-ads';

const REQUESTED_ADS_LIMIT = 20;

export const getAdsByCategoryAndUserLocation = async (
  category: Category,
  userLocation: Location,
) => {
  if (CATEGORIES[category].categoryId === CATEGORIES.vehicles.categoryId) {
    const carouselsNearMeVehicleAdsReq: CarouselsNearMeVehicleAdsReq = {
      city: userLocation.city,
      limit: REQUESTED_ADS_LIMIT,
    };

    const nearMeVehicleAds = await getNearMeVehicleAds(
      carouselsNearMeVehicleAdsReq,
    );

    return nearMeVehicleAds;
  } else {
    const { latitude: lat, longitude: lon } = userLocation;
    const carouselsNearMeAdsReq: CarouselsNearMeAdsReq = {
      lat,
      lon,
      catID: CATEGORIES[category].categoryId,
      limit: REQUESTED_ADS_LIMIT,
    };

    const nearMeAds = await getNearMeAds(carouselsNearMeAdsReq);

    return nearMeAds;
  }
};
