import { observer } from 'mobx-react-lite';
import styles from './favorites-drop-button.module.scss';
import { HeartIcon } from '@y2/mango/components/icons';
import text from './favorites-drop-button.text.json';
import noop from 'lodash/noop';

type Props = {
  likedCount?: number;
  click?: () => void;
};

export const FavoritesDropButton = observer(function FavoritesDropButton({
  likedCount = 0,
  click = noop,
}: Props) {
  return (
    <a
      href="/my-favorites"
      className={styles.dropButton}
      onClick={click}
      data-nagish="favorites-dropdown-button"
    >
      <span className={styles.iconBox}>
        <HeartIcon className={styles.icon} />
        {likedCount ? <span className={styles.count}>{likedCount}</span> : null}
      </span>
      <span className={styles.text}>{text.LIKED_ITEMS}</span>
    </a>
  );
});
