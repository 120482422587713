import { MouseEventHandler } from 'react';
import cn from 'classnames';
import styles from './like-button.module.scss';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isLiked: boolean;
  showAnimation?: boolean;
  className?: string;
};

export function LikeButton({
  onClick,
  isLiked,
  showAnimation,
  className,
}: Props) {
  return (
    <button
      className={cn(styles.button, className)}
      data-testid="like-button"
      onClick={(event) => {
        event.stopPropagation();
        onClick(event);
      }}
      data-nagish="like-icon-button"
    >
      <span
        className={cn(
          styles.heartIcon,
          isLiked && styles.isLiked,
          showAnimation && styles.isAnimate,
        )}
      />
    </button>
  );
}
