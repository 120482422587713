import { action, makeAutoObservable, runInAction } from 'mobx';
import * as api from '../../services/api';

export type Snapshot = {
  count: number | null;
};

export class AlertsModel {
  public count!: number | null;

  public constructor(initialState: Snapshot) {
    this.count = initialState.count;

    makeAutoObservable(this, {
      getAlertsCount: action.bound,
    });
  }

  public async getAlertsCount() {
    const count = await api.alerts.count();
    runInAction(() => {
      this.count = count.data?.data?.alertsCount ?? null;
    });
  }
}
