import * as api from '../../services/api';

type AuthApi = typeof api.auth;

const email = process.env.NX_YAD2_SITE_USERNAME;
const password = process.env.NX_YAD2_SITE_PASSWORD;

// prettier-ignore
const MISSING_CRED_MSG = [
  'CREDENTIALS NOT SUPPLIED.',
  'make sure you have a `.env` file (located at the project root)',
  'with the following variables defined:',
  'NX_YAD2_SITE_USERNAME=\'your@email.com\'',
  'NX_YAD2_SITE_PASSWORD=\'your_password\'',
  '(!) restart the app after changing`.env` file.',
].join('\n');

export function exposeAuth(authApi: AuthApi) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.login = () => {
    if (email && password) {
      authApi.login({ email, password });
    } else {
      // eslint-disable-next-line no-console
      console.error(MISSING_CRED_MSG);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.logout = () => {
    authApi.logout();
  };
}
