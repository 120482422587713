import { User, fromApiData } from './from-api-data';
import { isAxiosError } from '@y2/api-clients';
import * as api from '../api';

const HTTP_STATUS_UNAUTHORIZED = 401;

/**
 * @returns current user data or null if not loggedin
 */
export async function getCurrentUser(): Promise<User | null> {
  try {
    const res = await api.auth.getUserDetails();
    return fromApiData(res.data);
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === HTTP_STATUS_UNAUTHORIZED
    ) {
      return null;
    } else {
      throw error;
    }
  }
}
