/** istanbul ignore file **/
import type { SearchItem as RawSearchItem } from '@y2/api-clients/latest-searches';
import { createFieldCompareByCatId } from './fields-order';

type Field = {
  id: string;
  label: string;
  text: string;
  value: string;
};

export type SearchItem = {
  id: string;
  title: string;
  url: string;
  fields: Field[];
};

export function fromApiItem({
  id,
  title,
  url,
  fields,
  categoryId,
}: RawSearchItem): SearchItem {
  const fieldsCompare = createFieldCompareByCatId(categoryId);
  return {
    id,
    title,
    url,
    fields: Object.entries(fields)
      .map(([id, value]) => ({
        id,
        ...value,
      }))
      .sort(fieldsCompare),
  };
}
