/* istanbul ignore file */
import { User } from '../../../services/user';
import { makeAutoObservable } from 'mobx';

export type Snapshot = User;

export class UserModel {
  public id!: number;
  public firstName!: string;
  public lastName!: string;
  public uuid!: string;

  public constructor({ id, firstName, lastName, uuid }: Snapshot) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.uuid = uuid;

    makeAutoObservable(this);
  }

  public get fullName() {
    return [this.firstName, this.lastName].join(' ');
  }

  public get initials() {
    const firstNameInitial = this.firstName.charAt(0);
    const lastNameInitial = (this.lastName || '').charAt(0);

    return [firstNameInitial, lastNameInitial].join('');
  }
}
