import * as api from '../../../services/api';
import { CarouselsNearMeVehicleAdsReq } from '@y2/api-clients/carousels';
import {
  NearMeAdItem,
  getNearMeAdItemByCategory,
} from './get-near-me-ad-item-by-category';
import { log } from '@y2/log';

/**
 * fetches vehicle ads based on user's current city (default city: תל אביב)
 * @see spec https://gw.yad2.io/carousels/api-docs/#/new/getNearMeVehicleAds
 * @returns vehicle ads based on user's current location's coordinations and chosen category
 */
export async function getNearMeVehicleAds(
  carouselsNearMeVehicleAdsReq: CarouselsNearMeVehicleAdsReq,
): Promise<NearMeAdItem[]> {
  try {
    const res = await api.carousels.getNearMeVehicleAds(
      carouselsNearMeVehicleAdsReq,
    );
    const nearMeVehicleAds = res.data?.data || [];
    return nearMeVehicleAds.map(getNearMeAdItemByCategory);
  } catch (error) {
    log.error(error as Error, 'failed to execute getNearMeVehicleAds');
    return [];
  }
}
