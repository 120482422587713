/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { runInAction } from 'mobx';

function serialize(model: any) {
  const storeSnapshot = JSON.parse(JSON.stringify(model));

  return storeSnapshot;
}

export function useInspect(store: unknown) {
  useEffect(() => {
    Object.defineProperty(window, '__MBX_SNAPSHOT', {
      get() {
        runInAction(() => {
          // eslint-disable-next-line no-console
          console.log(serialize(store));
        });
      },
      configurable: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
