import { Snapshot } from './store';

export const initialState: Snapshot = {
  auth: {
    currentUser: null,
    loading: true,
  },
  latestSearches: {
    items: [],
    count: 0,
  },
  recommendations: [],
  alerts: {
    count: null,
  },
  favorites: {
    initialLoading: true,
    list: [],
    appName: 'homepage',
  },
  nearMeAds: {
    categoryLists: {
      realestate: [],
      vehicles: [],
      products: [],
    },
    selectedCategory: 'realestate',
  },
  location: {
    state: 'init',
  },
};
