/* eslint-disable no-underscore-dangle, @typescript-eslint/no-explicit-any */
import { log } from '@y2/log';

declare global {
  interface Window {
    _bcvma: any[];
    nanorep: any;
  }
}

export const setWidgetConfig = () => {
  window.nanorep = window.nanorep || {};
  window.nanorep.floatingWidget = window.nanorep.floatingWidget || {};

  const widget = window.nanorep.floatingWidget;
  widget.apiHost = undefined;
  widget.host = 'yad2.nanorep.co';
  widget.path = '/web/';
  widget.account = 'yad2';
  widget.protocol = 'https:';
  widget.on =
    widget.on ||
    function () {
      widget._calls = widget._calls || [];
      // eslint-disable-next-line prefer-rest-params
      widget._calls.push([].slice.call(arguments));
    };
};

export const setMachineConfig = (
  accountId: string,
  websiteId: string,
  chatId: string,
) => {
  window._bcvma = window._bcvma || [];

  window._bcvma.push(['setAccountID', accountId]);
  window._bcvma.push(['setParameter', 'WebsiteID', websiteId]);
  window._bcvma.push(['addFloat', { type: 'chat', id: chatId }]);
  window._bcvma.push(['pageViewed']);
};

export const setWidgetIdOnLoad = (widgetId: string) => {
  if (window.nanorep) {
    window.nanorep.floatingWidget.setConfigId(widgetId);
  } else {
    log.error(Error('failed to load nanorep'), '');
  }
};

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    // eslint-disable-next-line custom-rules/user-agent
    navigator.userAgent,
  );
