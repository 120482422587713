import {
  Logo,
  ResponsiveHeaderGrid,
  UserDropContainer,
  UserDropItem,
  InlineChatButton,
  FavoritesInlineButton,
  MenuListItems,
  menuData,
  UserDropChatButton,
  PublishAdLink,
  pushEnterChatHeaderAnalitycsEvent,
  pushEnterChatSideMenuAnalitycsEvent,
  useInlineDesktopChatButton,
} from '@y2/ui/header';
import { observer } from 'mobx-react-lite';
import { FavoritesDropDown } from '@y2/favorites/components/favorites-dropdown';
import { environment } from '../../environments/environment';
import { useStore } from '../../services/store';
import * as analytics from '../../features/analytics';
import texts from './header.texts.json';
import { goToLogin } from '@y2/auth-common/helpers';

const homepageUrl = environment.routes.mainSite;

type Props = {
  className?: string;
};

export const Header = observer(function Header({ className }: Props) {
  const store = useStore();
  const { currentUser } = store.auth;
  const hasInlineChatInDesktop = useInlineDesktopChatButton();

  const sendNavigationMenuAnalytics = (label: string) => {
    analytics.pushNavigationMenuClickEvent(label);
  };
  const isAnonymous = currentUser === null;

  const handleGoToLogin = () => {
    if (isAnonymous) {
      goToLogin();
    }
  };

  const handleGoToLogout = () => {
    sendNavigationMenuAnalytics(texts.logout);
    store.auth.logout();
  };

  return (
    <div className={className}>
      <ResponsiveHeaderGrid
        logoSlot={<Logo imageToUse={'yad2'} linkAddress={homepageUrl} />}
        mobileLogoSlot={
          <Logo imageToUse={'inverted'} linkAddress={homepageUrl} isSmall />
        }
        isTranslucent
        mobileInlineButtons={{
          left: (
            <InlineChatButton
              isUserLoggedIn={store.auth.isLoggedIn}
              sendAnalytics={pushEnterChatHeaderAnalitycsEvent}
            />
          ),
          right: (
            <FavoritesInlineButton
              count={store.favorites.list.length ?? undefined}
            />
          ),
        }}
        theme="white"
        userDropSlot={
          <UserDropContainer
            onClick={handleGoToLogin}
            onLogoutClick={handleGoToLogout}
            userInitials={currentUser?.initials}
            userFullName={currentUser?.fullName}
            userOptions={
              currentUser && (
                <UserDropItem
                  id="enterPersonal"
                  onClick={() =>
                    sendNavigationMenuAnalytics(texts.enterPersonal)
                  }
                />
              )
            }
            options={
              <>
                <UserDropChatButton
                  isUserLoggedIn={!!currentUser}
                  sendAnalytics={pushEnterChatSideMenuAnalitycsEvent}
                  isMobileOnly
                />
                <UserDropItem
                  id="favorites"
                  isMobileOnly
                  onClick={() => sendNavigationMenuAnalytics('Favorites')}
                  count={store.favorites.list.length}
                />
                <UserDropItem
                  id="lastSearches"
                  onClick={() => sendNavigationMenuAnalytics('Latest searches')}
                  count={store.latestSearches.count}
                />
                <UserDropItem
                  id="alerts"
                  onClick={() => sendNavigationMenuAnalytics(texts.alerts)}
                  count={store.alerts.count ?? undefined}
                />
                <UserDropItem id="customerServicePortal" isMobileOnly />
              </>
            }
          />
        }
        userDropDownExtension={
          <>
            {hasInlineChatInDesktop && (
              <InlineChatButton
                isUserLoggedIn={!!currentUser}
                sendAnalytics={pushEnterChatHeaderAnalitycsEvent}
              />
            )}
            <FavoritesDropDown
              favorites={store.favorites}
              isLoggedIn={!!currentUser}
              onRemoveItem={() =>
                analytics.pushFavoritesEvent({
                  type: 'unlike',
                })
              }
              onClick={() => sendNavigationMenuAnalytics('Favorites')}
            />
          </>
        }
        mainMenuSlot={<MenuListItems list={menuData} />}
        leftButtonSlot={
          <PublishAdLink
            onClick={() => sendNavigationMenuAnalytics(texts.publishAdButton)}
          />
        }
        hideMobileBackButton
      />
    </div>
  );
});
