import axios from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport } from '../helpers';

type BaseAd = {
  token: string;
  category_id: number;
  subcategory_id: number;
  price: number;
  address: {
    city?: {
      text: string;
    };
    street?: {
      text: string;
    };
    house?: {
      number: number;
    };
  };
  meta_data: {
    cover_image: string;
  };
};

export type SecondHandAd = BaseAd & {
  meta_data: BaseAd['meta_data'] & {
    description: string;
  };
};

export type RealEstateAd = BaseAd & {
  search_fields: {
    rooms_count: number;
  };
};

export type VehicleAd = BaseAd & {
  search_fields: {
    manufacturer?: {
      text: string;
    };
    model?: {
      text: string;
    };
    year: number;
  };
};

export type NearMeAd = SecondHandAd | RealEstateAd | VehicleAd;

export type CarouselsNearMeAdsReq = {
  lat: number;
  lon: number;
  catID: number;
  subCatID?: number;
  limit?: number;
};

export type CarouselsNearMeAdsRes = {
  data: {
    data: {
      docs: NearMeAd[];
    };
    message: string;
  };
};

export type CarouselsNearMeVehicleAdsReq = {
  city: string;
  limit?: number;
};

export type CarouselsNearMeVehicleAdsRes = {
  data: {
    data: NearMeAd[];
    message: string;
  };
};

const serviceName = 'carousels';

/**
 * client for `carousels` service
 * @see https://gw.yad2.io/carousels/api-docs/#
 */
export const carouselsClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });

  return {
    getNearMeAds: (
      params: CarouselsNearMeAdsReq,
    ): Promise<CarouselsNearMeAdsRes> => client.get(`/near-me`, { params }),
    getNearMeVehicleAds: (
      params: CarouselsNearMeVehicleAdsReq,
    ): Promise<CarouselsNearMeVehicleAdsRes> =>
      client.get(`/near-me/vehicles`, { params }),
  };
};
