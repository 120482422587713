export const DEFAULT_ACCOUNT_ID = '175912962140329782';

export const DEFAULT_WEBSITE_ID = '176213321653038733';

export const DEFAULT_CHAT_ID = '176213321242193983';

export const WIDGET_ID = {
  desktop: '1229757602',
  mobile: '1231592382',
};
