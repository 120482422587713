/* eslint-disable no-underscore-dangle */
import { fromApiData, FavoriteItem } from './from-api-data';
import * as anonymousUserAds from './anonymous-user';
import { LoggedUser } from './logged-user';
import { createLocalFavoritesInDb } from '../react-query/use-favorites/logged';
import { createFeedClient, FeedClient } from '@y2/api-clients';
import { favoritesClient, FavoritesClient } from '@y2/api-clients/favorites';
import {
  isCreateBeforeSecond,
  mapRecommerceProductToFavoriteItem,
} from '../components/favorites-item/helpers';

export type { FavoriteItem };

export type Config = {
  oldApiBasePath: string;
  gwApiBasePath: string;
};

export class FavoritesService {
  private _feed!: FeedClient;
  private _favorites!: FavoritesClient;
  private _loggedUser!: LoggedUser;

  public constructor({ oldApiBasePath, gwApiBasePath }: Config) {
    this._feed = createFeedClient(oldApiBasePath);
    this._favorites = favoritesClient();
    this._loggedUser = new LoggedUser(this._feed, this._favorites);
  }

  public async getAllAnonymous(): Promise<FavoriteItem[]> {
    const {
      resAll: resRecommerceData,
      mappedLikedItems: mappedLikedItemsRecommerce,
    } = await anonymousUserAds.getAllRecommerce();

    const { resAll, mappedLikedItems } = await anonymousUserAds.getAll(
      this._feed,
    );
    return [
      ...mapRecommerceProductToFavoriteItem(
        resRecommerceData,
        mappedLikedItemsRecommerce,
      ),
      ...resAll.map((item) =>
        fromApiData(item, mappedLikedItems?.[item.id]?.updatedAt),
      ),
    ].sort((first, second) =>
      isCreateBeforeSecond(first.createdAt, second.createdAt),
    );
  }

  public async getAllLoggedUser(): Promise<FavoriteItem[]> {
    const { favorites, recommerceFavorites, favoritesMap } =
      await this._loggedUser.getAll();
    return [
      ...recommerceFavorites,
      ...favorites.map((item) =>
        fromApiData(item, favoritesMap?.[item.id]?.updatedAt),
      ),
    ].sort((first, second) =>
      isCreateBeforeSecond(first.createdAt, second.createdAt),
    );
  }

  public async remove(isLoggedIn: boolean, id: string) {
    isLoggedIn
      ? await this._loggedUser.remove(id)
      : await anonymousUserAds.remove(id);
  }

  public async add(isLoggedIn: boolean, id: string) {
    if (isLoggedIn) {
      await this._loggedUser.add(id);
    } else {
      anonymousUserAds.add(id);
    }

    const res = await this._feed.get(id);
    const newItem = fromApiData(res.data);
    return newItem;
  }

  public async flushLocalToRemote() {
    await createLocalFavoritesInDb();
  }
}
