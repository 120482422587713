import { log } from '@y2/log';
import { action, makeAutoObservable, observable } from 'mobx';
import * as api from '../../services/api';
import { SearchItem, fromApiItem } from './adapter';

/* eslint-disable no-underscore-dangle */

export type Snapshot = {
  items: SearchItem[];
  count: number;
};

export class LatestSearchesModel {
  private _list!: SearchItem[];
  private _count!: number;

  public constructor(initialState: Snapshot) {
    type PrivateMembers = '_list' | '_resetData' | '_removeSync';
    this._list = initialState.items;
    this._count = initialState.count;

    makeAutoObservable<this, PrivateMembers>(this, {
      // shallow - do not track changes on the items inner properties, only on the list
      _list: observable.shallow,
      update: action.bound,
      _resetData: action.bound,
      _removeSync: action.bound,
    });
  }

  public get list() {
    return this._list;
  }

  public get count() {
    return this._count;
  }

  /**
   * updates the store latest searches state
   * @throws {never} logs errors and hide them from the UI
   */
  public async update() {
    try {
      const [itemsRes, countRes] = await Promise.all([
        api.latestSearches.getAll({ limit: 3 }),
        api.latestSearches.count(),
      ]);
      const updatedList = itemsRes.data.data.map(fromApiItem);
      this._resetData({
        items: updatedList,
        count: countRes.data.data,
      });
    } catch (error) {
      log.error(
        error as Error,
        'failed to execute update agt LatestSearchesModel',
      );
    }
  }

  /**
   * removes an item from store and from api service
   * @param id search item id
   * @throws {never} logs errors and hide them from the UI
   */
  public async remove(id: string) {
    this._removeSync(id);
    try {
      await api.latestSearches.remove([id]);
      await this.update();
    } catch (error) {
      log.error(
        error as Error,
        'failed to execute remove at LatestSearchesModel',
      );
    }
  }

  /**
   * reset the list with a new data
   */
  private _resetData({ items, count }: Snapshot) {
    this._list = items;
    this._count = count;
  }

  /**
   * removes an item from the list
   * @param id search item id
   */
  private _removeSync(id: string) {
    const updatedList = this._list.filter((item) => item.id !== id);
    this._list = updatedList;
  }
}
