/* istanbul ignore file */
import styles from './link-row.module.scss';
import text from './link-row.text.json';

export const LinkRow = () => (
  <li className={styles.linkRow} data-nagish="favorites-link-row-item">
    <a
      href="/my-favorites"
      className={styles.link}
      data-nagish="favorites-link-row-item-link"
    >
      {text.GO_TO_ALL_ADS}
    </a>
  </li>
);
