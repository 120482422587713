import React from 'react';

type Props = {
  accountId: string;
  websiteId: string;
};

export const NanorepForNoScript: React.FC<Props> = ({
  accountId,
  websiteId,
}) => (
  <div id="nanorep-plugin">
    <noscript>
      <a
        href="http://www.boldchat.com"
        title="Live Chat"
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt="Live Chat"
          src={`https://vms-eu.boldchat.com/aid/${accountId}/bc.vmi?wdid=${websiteId}`}
          width="1"
          height="1"
        />
      </a>
    </noscript>
  </div>
);
