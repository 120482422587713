import { createCompareFunc } from '../../../../utils/sort';

type Field = {
  id: string;
};

const getFieldId = (field: Field) => field.id;

export const createFieldsCompare = (order: string[]) =>
  createCompareFunc(order, getFieldId);
