import { useEffect } from 'react';
import * as api from '../../services/api';
import { exposeAuth } from './auth';

function exposeApi() {
  exposeAuth(api.auth);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.api = api;
}

type Options = {
  disabled: boolean;
};

export function useExposeApi({ disabled }: Options) {
  // expose api once after initial render
  useEffect(() => {
    if (!disabled) {
      exposeApi();
    }
  }, [disabled]);
}
