/* eslint-disable no-underscore-dangle */
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import {
  AbortError,
  getGeolocationPermissionState,
  getUserCoordinations,
  LocationState,
} from './location';
import { AddressMasterNearestPointReq } from '@y2/api-clients/address-master';
import { getNearestCity } from './adapter/get-nearest-city';
import { log } from '@y2/log';

export type Snapshot = {
  state: LocationState;
};

export class LocationModel {
  private _state: LocationState;

  public constructor(initialState: Snapshot) {
    type PrivateMembers = '_state';
    this._state = initialState.state;

    makeAutoObservable<this, PrivateMembers>(this, {
      _state: observable.ref,
      initialize: action,
      update: action,
    });
  }

  public get state() {
    return this._state;
  }

  public async initialize() {
    const permissionState = await getGeolocationPermissionState();
    if (permissionState === 'granted') {
      this.update();
    } else {
      runInAction(() => {
        this._state = permissionState === 'prompt' ? 'prompt' : 'error';
      });
    }
  }

  public async update() {
    try {
      const coordinations = await getUserCoordinations();
      const addressMasterNearestPointReq: AddressMasterNearestPointReq = {
        lat: coordinations.latitude,
        lon: coordinations.longitude,
      };
      const city = await getNearestCity(addressMasterNearestPointReq);

      runInAction(() => {
        this._state = {
          latitude: coordinations.latitude,
          longitude: coordinations.longitude,
          city,
        };
      });
    } catch (error) {
      if (!(error instanceof AbortError)) {
        // no need to send log when user aborts
        log.error(error as Error, 'failed to execute update at LocationModel');
      }
      runInAction(() => {
        this._state = error instanceof AbortError ? 'prompt' : 'error';
      });
    }
  }
}
