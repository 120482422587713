import { GoolgeTagEvents } from './dfp-item/types';

export const DFP_ID = 22346962;

export const DFP_API = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

export const MAX_SIZE_IN_SINGLE_REQUEST = 30;

export const ADS_GROUPING_REQ_DEBOUNCE_MILISECONDS = 200;

export const ROAD_BLOCK_ADS_GROUPING_REQ_DEBOUNCE_MILISECONDS = 250;

export const FAVORITES_USER_KEY = 'favorites_userid';

export const AB_TEST_KEY = 'abTestKey';

export const Y2_COHORT_2020 = 'y2_cohort_2020';

export const Y2_COHORT_2018 = 'y2_cohort_2018';

export const AB_TEST_FALLBACK_ID = 111;

export const COHORT_NOT_EXIST = 0;

export const dfpEvents: GoolgeTagEvents[] = [
  'impressionViewable',
  'slotOnload',
  'slotRenderEnded',
  'slotRequested',
  'slotResponseReceived',
  'slotVisibilityChanged',
];
