import { useEffect, useState } from 'react';
import { cohortGroups, getCohortGroup } from '@y2/cohort';

export const useInlineDesktopChatButton = () => {
  const [hasInlineDesktopChat, setHasInlineDesktopChat] = useState(false);

  useEffect(() => {
    const cohortGroup = getCohortGroup();

    setHasInlineDesktopChat(cohortGroup <= cohortGroups.D);
  }, []);

  return hasInlineDesktopChat;
};
