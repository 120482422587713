import { createFieldsCompare } from './create-fields-compare';

export const realEstateCompare = createFieldsCompare([
  'moshavimKibutzim',
  'topArea',
  'area',
  'city',
  'neighborhood',
  'street',
  'dealType',
  'property',
  'flatmates',
  'rooms',
  'price',
]);
