import identity from 'lodash/identity';

type SortBy<item, value> = (item: item) => value;

/**
 * creates sorting comparer from order array
 * @param order array of values indicating sorting order
 * @param sortBy optional, extracts value to order-by from each item
 * @returns sorting compareFunc
 * @see sort-compareFunc https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * @example
 * const compare = createCompareFunc(['model' ,'year', 'price']);
 *
 * console.log(['name', 'price', 'model'].sort(compare))
 * // ['model', 'price', 'name']
 *
 * console.log(['name', 'price', 'model', 'year', 'id'].sort(compare))
 * // ['model', 'year', 'price', 'name', 'id']
 *
 * @example
 * const compare = createCompareFunc(['model' ,'year', 'price'], item => item.id);
 * const input = [
 *  { id: 'name', value: 'chuck norris' },
 *  { id: 'price', value: '180,000' },
 *  { id: 'year', value: '1984' },
 *  { id: 'model', value: 'A4' },
 * ];
 *
 * console.log(input.sort(compare))
 * // [{ id: 'model', value: 'A4' },
 * // { id: 'year', value: '1984' },
 * // { id: 'price', value: '180,000' },
 * // { id: 'name', value: 'chuck norris' }]
 */
export const createCompareFunc = <item, value>(
  order: value[],
  sortBy: SortBy<item, value> = identity,
) => {
  // use hashmap for performance (getOrder in ~O(1))
  const orderMap = new Map(order.map((field, idx) => [field, idx]));

  const getOrder = (value: value) =>
    orderMap.get(value) ?? Number.POSITIVE_INFINITY;

  const compare = (item1: item, item2: item) =>
    getOrder(sortBy(item1)) - getOrder(sortBy(item2));

  return compare;
};
