/* istanbul ignore file */
import { log } from '@y2/log';
import texts from './texts.json';

export type Location = {
  latitude: number;
  longitude: number;
  city: string;
};

type Coordinations = {
  latitude: number;
  longitude: number;
};

export type LocationState = 'init' | 'prompt' | 'error' | Location;

// coordinations of tel aviv
export const DEFAULT_LOCATION: Location = {
  latitude: 32.0778532,
  longitude: 34.7750269,
  city: texts.telAviv,
};

export class AbortError extends Error {}

const getGeoLocationPosition = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

export const getGeolocationPermissionState =
  async (): Promise<PermissionState> => {
    try {
      const { state } = await navigator.permissions.query({
        name: 'geolocation',
      });
      return state;
      // eslint-disable-next-line custom-rules/enforce-catch-err-handling
    } catch (error) {
      return 'denied';
    }
  };

export const getUserCoordinations = async (): Promise<Coordinations> => {
  try {
    const position = await getGeoLocationPosition();
    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
  } catch (error) {
    if (
      error instanceof GeolocationPositionError &&
      error.code === GeolocationPositionError.PERMISSION_DENIED
    ) {
      const permissionState = await getGeolocationPermissionState();
      // eslint-disable-next-line custom-rules/no-nested-if-conditions
      if (permissionState === 'prompt') {
        throw new AbortError(
          `user dismissed the location permission popup with the 'x' button`,
        );
      }
    }
    throw error;
  }
};

export const isLocationAvailable = (state: LocationState): state is Location =>
  typeof state !== 'string';

export const withDefaultLocation = (state: LocationState): Location =>
  isLocationAvailable(state) ? state : DEFAULT_LOCATION;
