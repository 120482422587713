import { createFieldsCompare } from './create-fields-compare';

export const vehiclesCompare = createFieldsCompare([
  'accessoryCategory',
  'accessorySubCategory',
  'itemName',
  'CarSpecialID',
  'CarSpecialSubCatID',
  'CarSpecialSubSubCatID',
  'carSpecialSubCatTypeID',
  'carFamilyType',
  'carTag',
  'manufacturer',
  'model',
  'year',
  'price',
  'topArea',
  'area',
]);
