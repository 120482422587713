/**
 * @throws {Error} when the reversed mapping has duplicate keys
 */
export function reverseMapping<K extends string, V extends string>(
  mapping: Record<K, V>,
) {
  const result = {} as Record<V, K>;

  Object.entries<V>(mapping).forEach(([key, value]) => {
    if (value in result) {
      throw new Error(
        `duplicate keys. "${result[value]}" and "${key}" both are mapped to "${value}"`,
      );
    }
    result[value] = key as K;
  });

  return result;
}
