/* eslint-disable no-underscore-dangle */
import * as userService from '../../services/user';
import { UserModel, Snapshot as UserSnapshot } from './user/user.model';
import { action, makeAutoObservable, runInAction } from 'mobx';
import * as api from '../../services/api';

export type Snapshot = {
  currentUser: UserSnapshot | null;
  loading: boolean;
};

export class AuthModel {
  private _currentUser!: UserModel | null;
  private _loading!: boolean;

  public constructor({ currentUser, loading }: Snapshot) {
    this._loading = loading;
    this._currentUser = fromCurrentUserSnapshot(currentUser);

    makeAutoObservable(this, {
      fetchCurrentUser: action.bound,
      logout: action.bound,
    });
  }

  public get currentUser() {
    return this._currentUser;
  }

  public get loading() {
    return this._loading;
  }

  public get isLoggedIn() {
    return this.currentUser !== null;
  }

  public async fetchCurrentUser() {
    this._loading = true;
    try {
      const data = await userService.getCurrentUser();
      runInAction(() => {
        this._currentUser = fromCurrentUserSnapshot(data);
        this._loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this._loading = false;
      });
      // continue error propagation
      throw error;
    }
  }

  public async logout() {
    this._loading = true;
    await api.auth.logout();
    runInAction(() => {
      this._currentUser = null;
      this._loading = false;
    });
  }
}

function fromCurrentUserSnapshot(user: Snapshot['currentUser']) {
  return user ? new UserModel(user) : null;
}
