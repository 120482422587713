import {
  createBooleanEncoding,
  createMapEncoding,
} from './helpers/create-encoding';

/**
 * according to specs in:
 * @see https://docs.google.com/spreadsheets/d/1OPSq9Jq8WTRfB0hvxTCa9L4ltGvx88C84ezpA0TERPs#gid=267080539
 */

// prettier-ignore
const adTypeEncoding = createMapEncoding({
  'Private': '0',
  'Business': '1',
  'Yad1': '2',
} as const);

// prettier-ignore
const colorEncoding = createMapEncoding({
  'Grey':             '0',
  'Pink':             '1',
  'Yellow':           '2',
  'Platinum':         '3',
  'Boost':            '4',
  'Promo':            '5',
  'Yad1 - Basic':	    '6',
  'Yad1 - Pro':	      '7',
  'Yad1 - Premium':	  '8',
  'Yad1 - Promotion':	'9',
  'Platinum2 (Solo)': 'A',
} as const);

const imageEncoding = createBooleanEncoding();

const videoEncoding = createBooleanEncoding();

// prettier-ignore
const priceEncoding = createMapEncoding({
  'Without price':	                    '0',
  'With price':	                        '1',
  'With starting price (for dealers)':  '2',
} as const);

// prettier-ignore
const locationEncoding = createMapEncoding({
  'Main feed listings':	                            'L',
  'Map feed listings':	                            'M',
  'Ad inside ad':	                                  'A',
  'Bottom 4 - yad1':	                              'B',
  'Minisite':	                                      'S',
  'My alerts':	                                    'N',
  'Recommendation Engine - Feed':	                  'R',
  'Recommendation Engine - In Ad Impressions':	    'H',
  'Recommendation Engine - Home page/main search':  'C',
  'Recommendation Engine - Lobby':	                'G',
} as const);

/** any character other then "&" with length of 1 or more (non-eager), followed by "&" */
const idPattern = /([^&]+?)&/.source;

/** any character with a single length */
const adTypePattern = /(.)/.source;

/** any character with a single length */
const colorPattern = /(.)/.source;

/** any character with a single length */
const imagePattern = /(.)/.source;

/** any character with a single length */
const videoPattern = /(.)/.source;

/** any character with a single length */
const pricePattern = /(.)/.source;

/** single large english letter */
const locationTypePattern = /([A-Z])/.source;

/** 3 digits */
const locationIndexPattern = /(\d{3})/.source;

const fullRegex = new RegExp(
  [
    '^',
    idPattern,
    adTypePattern,
    colorPattern,
    imagePattern,
    videoPattern,
    pricePattern,
    locationTypePattern,
    locationIndexPattern,
    '$',
  ].join(''),
);

/**
 * @throws {Error} when input code structure is invalid
 */
export function decodeSingle(code: string) {
  const result = fullRegex.exec(code);
  // console.log(result);

  if (result) {
    const [, id, adType, color, image, video, price, locationType, index] =
      result;

    return {
      id,
      adType: adTypeEncoding.decode(adType),
      color: colorEncoding.decode(color),
      withImage: imageEncoding.decode(image),
      withVideo: videoEncoding.decode(video),
      price: priceEncoding.decode(price),
      locationType: locationEncoding.decode(locationType),
      locationIndex: parseInt(index, 10),
    };
  }

  throw new Error(`code "${code}" is invalid`);
}

/**
 * @throws {Error} when input code structure is invalid
 */
export const decodeMulti = (code: string) => code.split('_').map(decodeSingle);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FirstParam<T extends (...args: any) => any> = Parameters<T>[0];

export type ItemEventData = {
  id: string;
  adType: FirstParam<typeof adTypeEncoding.encode>;
  color: FirstParam<typeof colorEncoding.encode>;
  withImage: FirstParam<typeof imageEncoding.encode>;
  withVideo: FirstParam<typeof videoEncoding.encode>;
  price: FirstParam<typeof priceEncoding.encode>;
  locationType: FirstParam<typeof locationEncoding.encode>;
  locationIndex: number;
};

export function encodeSingle({
  id,
  adType,
  color,
  withImage,
  withVideo,
  price,
  locationType,
  locationIndex,
}: ItemEventData): string {
  return [
    id,
    '&',
    adTypeEncoding.encode(adType),
    colorEncoding.encode(color),
    imageEncoding.encode(withImage),
    videoEncoding.encode(withVideo),
    priceEncoding.encode(price),
    locationEncoding.encode(locationType),
    // eslint-disable-next-line no-magic-numbers
    locationIndex.toFixed(0).padStart(3, '0'),
  ].join('');
}

export const encodeMulti = (items: ItemEventData[]): string =>
  items.map(encodeSingle).join('_');

export const values = {
  adType: adTypeEncoding.values,
  color: colorEncoding.values,
  price: priceEncoding.values,
  locationType: locationEncoding.values,
};
