import { createFieldsCompare } from './create-fields-compare';

export const productsCompare = createFieldsCompare([
  'category',
  'item',
  'ageGenderGroup',
  'type',
  'salesSubCat4ID',
  'manufacturer',
  'manufacturID_model',
  'price',
  'topArea',
  'area',
]);
