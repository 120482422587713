import Script from 'next/script';
import { GLASSIX_API_KEY, GLASSIX_SNIPPET_ID } from './consts';

export const GlassixChatBubble = () => (
  <Script
    id="glassix-chat-bubble"
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
    var widgetOptions = {
      apiKey: "${GLASSIX_API_KEY}",
      snippetId: "${GLASSIX_SNIPPET_ID}",
    };

    (function(n){
      var u = function() {
        if (GlassixWidgetClient && typeof GlassixWidgetClient === "function") {
          window.widgetClient = new GlassixWidgetClient(n);
          widgetClient.attach();
          if (window.glassixWidgetScriptLoaded) {
            window.glassixWidgetScriptLoaded();
          }
        } else {
          f();
        }
      };
      var f = function() {
        r.onload = u;
        r.src = "https://cdn.glassix.net/clients/widget.1.2.min.js";
        document.body.removeChild(t);
        i.parentNode.insertBefore(r, i);
      };
      var i = document.getElementsByTagName("script")[0];
      var t = document.createElement("script");
      var r = t.cloneNode();
      t.async = true;
      t.type = "text/javascript";
      t.crossOrigin = "anonymous";
      t.id = "glassix-widget-script";
      t.onload = u;
      t.src = "https://cdn.glassix.com/clients/widget.1.2.min.js";
      if (!document.getElementById(t.id) && document.body) {
        i.parentNode.insertBefore(t, i);
        t.onerror = f;
      }
    })(widgetOptions);`,
    }}
  />
);
