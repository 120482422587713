import { analytics } from '@y2/google-tag-manager';

export const pushCategoryLinkClickEvent = (
  eventLabel: Record<string, string>,
) => {
  analytics.pushEvent({
    GA_event_category: 'Home view',
    GA_event_action: 'category link clicked',
    ...eventLabel,
  });
};

export const pushNavigationMenuClickEvent = (label: string) => {
  analytics.pushEvent({
    GA_event_category: 'Navigation',
    GA_event_action: 'Navigation menu clicked',
    GA_event_label: label,
  });
};

export const pushNavigationSubMenuClickEvent = (label: string) => {
  analytics.pushEvent({
    GA_event_category: 'Navigation',
    GA_event_action: 'Navigation sub-menu clicked',
    GA_event_label: label,
  });
};

type FavoritesEvent = {
  type: 'like' | 'unlike';
  component?: string;
};

export const pushFavoritesEvent = ({ type, component }: FavoritesEvent) => {
  analytics.pushEvent({
    GA_event_category: 'Home view',
    GA_event_action: 'Like ad clicked',
    GA_event_label: type,
    GA_event_label_value: component as string,
  });
};

export const pushNearMeLikeAdEvent = ({ type, component }: FavoritesEvent) => {
  analytics.pushEvent({
    GA_event_category: 'Ad view',
    GA_event_action: 'Like ad clicked',
    GA_event_label: type,
    GA_event_label_value: component as string,
  });
};

export const pushLatestSearchesEvent = () => {
  analytics.pushEvent({
    GA_event_category: 'latest searches',
    GA_event_action: 'Search committed',
    GA_event_label: 'Search committed',
    SearchType: 'latest searches',
    SearchDuration: null,
  });
};

export const pushLeadingCategoriesEvent = (label: string) => {
  analytics.pushEvent({
    GA_event_category: 'Home view',
    GA_event_action: 'category box clicked',
    GA_event_label: label,
  });
};

export const pushPageEvent = (userId: number | undefined) => {
  analytics.pushPageEvent({
    ClientStatus: userId ? 'loggedin' : 'unknown',
    PageVirtualURL: window.location.href,
    PageType: 'Home view',
    PageLayout: 'list',
    UserID: (userId?.toString() as string) ?? null,
  });
};

export const pushBannerClickEvent = (label: string) => {
  analytics.pushEvent({
    GA_event_category: 'home view',
    GA_event_action: 'promotion clicked',
    GA_event_label: label,
  });
};
