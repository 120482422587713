/* eslint-disable no-underscore-dangle */
import type { FeedClient } from '@y2/api-clients';
import type { FavoritesClient } from '@y2/api-clients/favorites';
import type { FavoriteItem } from '@y2/api-clients/favorites.types';
import { mapRecommerceFavoriteItemToFavoriteItem } from '../components/favorites-item/helpers';

export class LoggedUser {
  private _feedClient!: FeedClient;
  private _favoritesClient!: FavoritesClient;

  public constructor(feedClient: FeedClient, favoritesClient: FavoritesClient) {
    this._feedClient = feedClient;
    this._favoritesClient = favoritesClient;
  }
  public async getAll() {
    const res = await this._favoritesClient.getAll();
    const recommerceFavorites = mapRecommerceFavoriteItemToFavoriteItem(
      res?.data?.data?.recommerceFavorites,
    );

    const favorites = res?.data?.data?.favorites;

    const favoritesMap = favorites.reduce(
      (allFavorites, favorite) => ({
        ...allFavorites,
        [favorite.orderId]: favorite,
      }),
      {} as Record<string, FavoriteItem>,
    );

    const ids = Object.keys(favoritesMap);

    if (!ids?.length) {
      return { recommerceFavorites, favorites: [], favoritesMap: {} };
    }

    const feedRes = await this._feedClient.getMany(ids);
    return {
      recommerceFavorites,
      favorites: feedRes.data,
      favoritesMap,
    };
  }

  public async remove(id: string) {
    await this._favoritesClient.remove(id);
  }

  public async add(id: string) {
    await this._favoritesClient.add(id);
  }

  public async addMulti(ids: string[]) {
    await this._favoritesClient.addMulti(ids);
  }
}
