/* eslint-disable no-underscore-dangle */
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { NearMeAdItem } from './adapter/get-near-me-ad-item-by-category';
import { Location } from '../location/location';
import { CATEGORIES, Category } from './categories';
import { getAdsByCategoryAndUserLocation } from './ads';

export type Snapshot = {
  categoryLists: Record<Category, NearMeAdItem[]>;
  selectedCategory: Category;
};

export class NearMeAdsModel {
  private _categoryLists!: Record<Category, NearMeAdItem[]>;
  private _selectedCategory!: Category;

  public constructor(initialState: Snapshot) {
    type PrivateMembers = '_categoryLists' | '_selectedCategory';

    this._categoryLists = initialState.categoryLists;
    this._selectedCategory = initialState.selectedCategory;

    makeAutoObservable<this, PrivateMembers>(this, {
      _categoryLists: observable.shallow,
      _selectedCategory: true,
      resetCategoryLists: action.bound,
      update: action.bound,
      changeCategory: action.bound,
    });
  }

  public get categoryLists() {
    return this._categoryLists;
  }

  public get selectedCategory() {
    return this._selectedCategory;
  }

  public changeCategory(selectedCategory: Category, userLocation: Location) {
    this._selectedCategory = selectedCategory;
    if (this._categoryLists[selectedCategory].length === 0) {
      this.update(userLocation);
    }
  }

  public async update(userLocation: Location) {
    const ads = await getAdsByCategoryAndUserLocation(
      this._selectedCategory,
      userLocation,
    );
    runInAction(() => {
      this._categoryLists[this._selectedCategory] = ads;
    });
  }

  public resetCategoryLists() {
    for (const category of Object.keys(CATEGORIES) as Category[]) {
      this._categoryLists[category] = [];
    }
  }
}
