import * as api from '../../../services/api';
import { CarouselsNearMeAdsReq } from '@y2/api-clients/carousels';
import {
  NearMeAdItem,
  getNearMeAdItemByCategory,
} from './get-near-me-ad-item-by-category';
import { log } from '@y2/log';

/**
 * fetches ads based on user's current location's coordinations and chosen category (default category: realestate, default location: tel aviv)
 * @see spec https://gw.yad2.io/carousels/api-docs/#/new/getNearMeAds
 * @returns ads based on user's current location's coordinations and chosen category
 */
export async function getNearMeAds(
  carouselsNearMeAdsReq: CarouselsNearMeAdsReq,
): Promise<NearMeAdItem[]> {
  try {
    const res = await api.carousels.getNearMeAds(carouselsNearMeAdsReq);
    const nearMeAds = res.data?.data?.docs || [];
    return nearMeAds.map(getNearMeAdItemByCategory);
  } catch (error) {
    log.error(error as Error, 'failed to execute getNearMeAds');
    return [];
  }
}
