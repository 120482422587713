/* istanbul ignore file */
import { observer } from 'mobx-react-lite';
import { LikeButton } from '../like-button';
import { FavoriteItem } from '../../service';
import styles from './favorites-drop-item.module.scss';
import text from './favorites-drop-item.text.json';
import { ItemInfoColumn } from './item-info-column/item-info-column';
import { getItemPageUrl, getImageUrl } from '../favorites-item/helpers';

type Props = {
  item: FavoriteItem;
  onDislike: () => void;
  appName: string;
  index: number;
};

export const FavoritesDropItem = observer(function FavoritesDropItem({
  item,
  onDislike,
  appName,
  index,
}: Props) {
  const { images } = item;

  return (
    <li className={styles.box} data-nagish="favorites-dropdown-item">
      <LikeButton
        onClick={onDislike}
        isLiked={true}
        className={styles.likePosition}
      />
      <a
        className={styles.link}
        href={getItemPageUrl(item, appName, index)}
        data-nagish="favorites-dropdown-link"
      >
        <div className={styles.imgContainer}>
          <img
            className={styles.img}
            src={getImageUrl(images.main, images.additional)}
            alt={text.FAVORITES_IMAGE}
          />
        </div>
        <ItemInfoColumn item={item} />
        <ItemInfoColumn item={item} isPrice />
      </a>
    </li>
  );
});
