import { categories } from '@y2/categories';
import { petsCompare } from './pets';
import { productsCompare } from './products';
import { realEstateCompare } from './real-estate';
import { vehiclesCompare } from './vehicles';

type ParametersTuple = Parameters<typeof vehiclesCompare>;
type Field = ParametersTuple[0];

const mapping: { [key: number]: (item1: Field, item2: Field) => number } = {
  [categories.vehicles.id]: vehiclesCompare,
  [categories.realEstate.id]: realEstateCompare,
  [categories.products.id]: productsCompare,
  [categories.pets.id]: petsCompare,
};

export function createFieldCompareByCatId(categoryId: number) {
  return mapping[categoryId];
}
