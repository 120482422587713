/**
 * this mobile check is an exception (FOR DFP ONLY) due to dfp product demands
 * please avoid checking device in ts, only in css breakpoints
 */
export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    // eslint-disable-next-line custom-rules/user-agent
    navigator.userAgent,
  );
};
