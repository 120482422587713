/* eslint-disable no-underscore-dangle */
import { analytics } from '@y2/google-tag-manager';
import debounce from 'lodash/debounce';
import { encodeMulti, ItemEventData } from './encoding';

const initialImpressionDebounceMS = 1000;

export class EventAggregator {
  private _initialImpressions!: Set<ItemEventData>;
  private _remainingImpressions!: Map<string, ItemEventData>;
  private _isInitialImpressionsSent = false;
  private _isInteractionImpressionsSent = false;

  private readonly _sendInitialEventsDebounced = debounce(
    this._sendInitialEventsOnce,
    initialImpressionDebounceMS,
  );

  constructor(items: ItemEventData[]) {
    this._initialImpressions = new Set();

    this._remainingImpressions = new Map(items.map((item) => [item.id, item]));
  }

  public addEvent(itemId: string) {
    if (this._isInitialImpressionsSent) {
      return;
    }
    const item = this._remainingImpressions.get(itemId);
    if (item === undefined) {
      // already sent
      return;
    }
    this._remainingImpressions.delete(itemId);
    this._initialImpressions.add(item);
    this._sendInitialEventsDebounced();
  }

  public notifyInteraction() {
    // makes sure that initial-impressions-event will not be sent after interaction event
    this._isInitialImpressionsSent = true;

    if (this._isInteractionImpressionsSent === false) {
      this._isInteractionImpressionsSent = true;
      sendAggregatedEvent(this._remainingImpressions.values());
    }
  }

  private _sendInitialEventsOnce() {
    if (this._isInitialImpressionsSent) {
      return;
    }
    this._isInitialImpressionsSent = true;

    sendAggregatedEvent(this._initialImpressions.values());
  }
}

function sendAggregatedEvent(items: IterableIterator<ItemEventData>) {
  analytics.pushEvent({
    event: 'feedAdImp',
    feedAdImpArr: encodeMulti(Array.from(items)),
  });
}
